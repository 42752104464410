import { FC } from 'react';
import { calcZRotate } from '../helpers';
import { IBaseIconProps } from './types';

const MotorcycleBaseIcon: FC<IBaseIconProps> = ({ driverColor, height, children, rotateAngle }) => (
	<svg
		width='48'
		height={height}
		viewBox={`0 0 48 ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{
			transform: `rotateZ(${calcZRotate(rotateAngle)}deg)`,
		}}
	>
		<path
			d='M11.4619 34C10.1926 34 9.24431 32.8327 9.50436 31.5903L13.2601 13.646C13.4144 12.9091 13.8401 12.2571 14.4528 11.8194L22.2563 6.24551C23.2994 5.50044 24.7006 5.50044 25.7437 6.24551L33.5472 11.8194C34.1599 12.2571 34.5856 12.9091 34.7399 13.646L38.4956 31.5903C38.7557 32.8327 37.8074 34 36.5381 34L24 34L11.4619 34Z'
			fill={driverColor}
		/>
		<path
			d='M24.0328 25.1433C24.8592 25.1433 25.6857 25.1433 26.5122 25.1433C27.0724 25.1433 27.3295 25.3916 27.3295 25.9523C27.3387 27.607 27.3387 29.2525 27.3295 30.9072C27.3295 31.4588 27.0632 31.7254 26.5122 31.7254C24.8592 31.7346 23.2155 31.7346 21.5625 31.7254C21.0023 31.7254 20.7452 31.4588 20.7452 30.898C20.736 29.2433 20.736 27.5978 20.7452 25.9431C20.7452 25.3824 21.0023 25.1433 21.5625 25.1342C22.3798 25.1342 23.2063 25.1433 24.0328 25.1433Z'
			fill='white'
		/>
		<path
			d='M20.0211 19.2819C20.1167 18.6986 19.8872 18.077 20.2124 17.5129C20.2315 17.4842 20.1932 17.4268 20.1837 17.379C20.0689 16.6522 20.3749 16.3749 21.073 16.5853C21.4077 16.6809 21.4268 16.9678 21.3121 17.1973C21.1877 17.4268 21.2164 17.5989 21.2451 17.8284C21.3025 18.2683 21.2738 18.7177 21.2547 19.1672C21.2356 19.7218 21.3216 20.2477 21.7041 20.6876C21.8189 20.8119 21.7902 20.9458 21.7806 21.0892C21.7232 22.428 22.756 23.5181 24.0565 23.5086C25.3666 23.499 26.3611 22.4089 26.3037 21.051C26.2941 20.9171 26.2846 20.8024 26.3802 20.6972C26.7818 20.2477 26.8296 19.7122 26.8487 19.1385C26.8679 18.6126 26.6957 18.0675 26.9539 17.5511C27.0113 17.4364 26.8487 17.3694 26.8009 17.2738C26.7053 17.073 26.6766 16.853 26.8296 16.6905C27.0591 16.461 27.3747 16.5757 27.652 16.5853C27.8433 16.5949 27.9198 16.7574 27.9293 16.9295C28.0058 18.1535 28.264 19.3967 28.0919 20.592C27.7189 23.193 26.0742 24.5317 23.3011 24.1492C21.1017 23.8337 19.8012 22.0359 19.9924 19.8079C20.002 19.6357 20.0116 19.4541 20.0211 19.2819Z'
			fill='white'
		/>
		<path
			d='M23.7762 23.1976C22.7963 23.08 22.0497 22.2605 22.0497 21.2432C22.0497 21.2384 22.0497 21.2336 22.0497 21.2288C22.0497 21.2186 22.0497 21.2084 22.0498 21.1982C22.0545 20.5223 22.299 19.9673 22.7159 19.5947C23.0298 19.278 23.4277 19.0805 23.8497 19.0765C23.9069 19.0765 23.9634 19.0783 24.019 19.0817C24.0746 19.0783 24.1311 19.0765 24.1883 19.0765C24.6103 19.0805 25.0082 19.278 25.3221 19.5947C25.739 19.9673 25.9835 20.5223 25.9882 21.1982C25.9883 21.2084 25.9883 21.2186 25.9883 21.2288C25.9883 21.2336 25.9883 21.2384 25.9883 21.2432C25.9883 22.2605 25.2416 23.08 24.2618 23.1976C24.188 23.2065 24.113 23.2114 24.0368 23.212C24.0308 23.2121 24.0249 23.2121 24.019 23.2121C24.0131 23.2121 24.0071 23.2121 24.0012 23.212C23.925 23.2114 23.8499 23.2065 23.7762 23.1976Z'
			fill='white'
		/>
		<path
			d='M24.0183 17.5681C23.4254 17.5681 22.8516 17.5011 22.2683 17.3864C21.9719 17.3194 21.9528 17.1664 21.9528 16.9178C21.9528 16.6692 22.0388 16.564 22.2779 16.5736C22.5552 16.5831 22.7178 16.4397 22.8134 16.1911C22.9759 15.7608 23.2915 15.5886 23.7505 15.6269C23.9704 15.646 24.1999 15.646 24.4199 15.6269C24.8215 15.5982 25.1084 15.7512 25.2518 16.1146C25.3761 16.4014 25.5387 16.6023 25.883 16.5831C26.1411 16.564 26.1507 16.7457 26.1411 16.9369C26.1316 17.1091 26.189 17.2908 25.9021 17.3577C25.2805 17.5107 24.6494 17.5681 24.0183 17.5681Z'
			fill='white'
		/>
		<path
			d='M24.0278 18.8279C23.9688 18.8301 23.9058 18.8325 23.8381 18.8325C23.8371 18.8326 23.8362 18.8326 23.8352 18.8326C23.7232 18.8536 23.6197 18.866 23.5268 18.8645C23.3925 18.879 23.256 18.8876 23.14 18.8612C22.9618 18.8278 22.949 18.6711 22.9354 18.5046C22.9256 18.3851 22.9154 18.2606 22.8436 18.1727C22.8187 18.1451 22.7946 18.1166 22.7706 18.0883C22.7116 18.0186 22.6533 17.9498 22.5854 17.8954C22.5083 17.8355 22.4466 17.7832 22.462 17.6974C22.4563 17.6728 22.4604 17.6439 22.4781 17.6085C22.5154 17.5338 22.5703 17.5467 22.629 17.5604C22.6454 17.5643 22.6622 17.5682 22.6789 17.5703C23.1446 17.6576 23.6103 17.7031 24.0761 17.708C24.5418 17.7031 25.0075 17.6576 25.4732 17.5703C25.49 17.5682 25.5067 17.5643 25.5231 17.5604C25.5818 17.5467 25.6367 17.5338 25.6741 17.6085C25.6917 17.6439 25.6958 17.6728 25.6902 17.6974C25.7055 17.7832 25.6438 17.8355 25.5668 17.8954C25.4988 17.9498 25.4405 18.0186 25.3815 18.0883C25.3575 18.1166 25.3335 18.1451 25.3086 18.1727C25.2367 18.2606 25.2265 18.3851 25.2168 18.5046C25.2031 18.6711 25.1903 18.8278 25.0121 18.8612C24.8961 18.8876 24.7596 18.879 24.6253 18.8645C24.5325 18.866 24.4289 18.8536 24.3169 18.8326C24.3159 18.8326 24.315 18.8326 24.3141 18.8325C24.2463 18.8325 24.1834 18.8301 24.1244 18.8279C24.108 18.8272 24.0919 18.8266 24.0761 18.8261C24.0602 18.8266 24.0442 18.8272 24.0278 18.8279Z'
			fill='white'
		/>
		<path
			d='M26.2815 20.5535C25.996 20.0184 25.7797 19.4929 25.4076 19.0629C25.174 18.7954 25.373 18.461 25.4855 18.1839C25.5893 17.9355 25.8143 17.8399 26.0739 17.9259C26.3421 18.0119 26.4805 18.2126 26.4805 18.5183C26.4805 19.0534 26.4719 19.598 26.4632 20.1331C26.4546 20.2955 26.42 20.4198 26.2815 20.5535Z'
			fill='white'
		/>
		<path
			d='M21.8227 20.5535C21.7059 20.4473 21.6725 20.3314 21.6725 20.1963C21.6641 19.6362 21.6558 19.0762 21.6558 18.5162C21.6558 18.1493 21.8311 17.9369 22.1233 17.8982C22.3988 17.8693 22.5991 18.0334 22.6742 18.3617C22.7577 18.7093 22.8078 18.9797 22.5156 19.2983C22.2234 19.6266 22.0648 20.1094 21.8227 20.5535Z'
			fill='white'
		/>
		<path
			d='M24.0373 14.6671C23.2628 14.6576 23.3967 14.6958 23.3967 14.036C23.3871 13.3762 23.5975 13.0702 24.0469 13.0702C24.525 13.0702 24.6685 13.3762 24.7258 14.1603C24.7737 14.801 24.372 14.648 24.0373 14.6671Z'
			fill='white'
		/>
		<path
			d='M22.0815 24.1378C22.0264 24.1169 21.9764 24.0952 21.9336 24.0728C21.9336 24.102 21.9332 24.1329 21.9327 24.1648C21.9319 24.215 21.9311 24.2677 21.9321 24.3205C21.9296 24.3867 21.9382 24.4567 21.9467 24.5261C21.954 24.5867 21.9614 24.6469 21.9614 24.7039C21.971 24.8569 22.2196 24.8378 22.2196 24.8378C22.2196 24.8378 23.0698 24.8447 24.0035 24.8439C24.9371 24.8447 25.7873 24.8378 25.7873 24.8378C25.7873 24.8378 26.036 24.8569 26.0455 24.7039C26.0455 24.6469 26.0529 24.5867 26.0603 24.5261C26.0687 24.4567 26.0773 24.3867 26.0748 24.3205C26.0758 24.2677 26.075 24.215 26.0742 24.1648C26.0737 24.1329 26.0733 24.102 26.0733 24.0728C26.0305 24.0952 25.9805 24.1169 25.9254 24.1378C25.8945 24.1447 25.8606 24.1575 25.8265 24.1727C25.3816 24.3195 24.725 24.417 24.725 24.417C24.725 24.417 24.4074 24.4326 24.0035 24.4354C23.5995 24.4326 23.2819 24.417 23.2819 24.417C23.2819 24.417 22.6253 24.3195 22.1804 24.1727C22.1463 24.1575 22.1124 24.1447 22.0815 24.1378Z'
			fill='white'
		/>
		<path
			d='M24.0278 15.3755C23.8366 15.3755 23.6453 15.3755 23.4636 15.3755C23.3011 15.3755 23.1863 15.3468 23.1959 15.1364C23.2054 14.9739 23.2915 14.9165 23.4445 14.9165C23.8557 14.9165 24.2669 14.9165 24.6781 14.9165C24.8693 14.9165 24.9267 15.0217 24.9171 15.1843C24.9076 15.3659 24.7832 15.3659 24.6494 15.3659C24.439 15.3755 24.2286 15.3755 24.0278 15.3755Z'
			fill='white'
		/>
		<path
			d='M21.5129 17.2642C21.4601 17.2642 21.4172 17.2214 21.4172 17.1686L21.4172 16.9009C21.4172 16.848 21.4601 16.8052 21.5129 16.8052L21.7902 16.8052C21.843 16.8052 21.8858 16.848 21.8858 16.9009L21.8858 17.1686C21.8858 17.2214 21.843 17.2642 21.7902 17.2642L21.5129 17.2642Z'
			fill='white'
		/>
		<path
			d='M26.2846 17.2642C26.2318 17.2642 26.189 17.2214 26.189 17.1686L26.189 16.9009C26.189 16.848 26.2318 16.8052 26.2846 16.8052L26.5619 16.8052C26.6147 16.8052 26.6575 16.848 26.6575 16.9009L26.6575 17.1686C26.6575 17.2214 26.6147 17.2642 26.5619 17.2642L26.2846 17.2642Z'
			fill='white'
		/>
		<path
			d='M19.9547 17.2642C19.7921 17.2642 19.6774 17.2356 19.687 17.0252C19.6965 16.8626 19.7826 16.8052 19.9356 16.8052C20.2511 16.8052 20.0886 16.8052 20.0886 16.8052L20.0886 17.2547C20.0886 17.2547 20.0945 17.2642 19.9547 17.2642Z'
			fill='white'
		/>
		<path
			d='M28.1816 16.8053C28.3442 16.8053 28.459 16.834 28.4494 17.0443C28.4398 17.2069 28.3538 17.2643 28.2008 17.2643C27.8852 17.2643 28.0478 17.2643 28.0478 17.2643L28.0478 16.8148C28.0478 16.8148 28.0418 16.8053 28.1816 16.8053Z'
			fill='white'
		/>
		<path
			d='M23.0343 31.9756L25.1021 31.9756L25.1021 32.3694C25.1021 32.587 24.9257 32.7633 24.7082 32.7633L23.4282 32.7633C23.2106 32.7633 23.0343 32.587 23.0343 32.3694L23.0343 31.9756Z'
			fill='white'
		/>
		<path
			d='M24 8L26.5981 12.5L21.4019 12.5L24 8Z'
			fill='white'
		/>
		{children}
	</svg>
);

export default MotorcycleBaseIcon;
