import { IBranch, IVendor } from 'types/api';
import { ESupportedLanguages } from '../common';

export enum EVendorUserType {
	VENDOR_MANAGER = 'vendor-manager',
	BRANCH_MANAGER = 'branch-manager',
	FINANCE_MANAGER = 'finance-manager',
}

export enum EVendorUserStatus {
	PENDING = 'pending', //0
	ACTIVE = 'active', //1,
	BLOCKED = 'blocked', //2,
}

interface IVendorUserVendors {
	id: IVendor['id'];
	name: IVendor['info']['name'];
	name_ar: IVendor['info']['name_ar'];
}

interface IVendorUserStores {
	id: IBranch['id'];
	name: IBranch['info']['name'];
	name_ar: IBranch['info']['name_ar'];
}

export interface IVendorUserListItemResponse {
	id: number;
	first_name: string;
	last_name: string;
	type: EVendorUserType;
	email: string;
	country_code: string;
	phone_number: string;
	status: EVendorUserStatus;
	language: ESupportedLanguages;
	vendors: IVendorUserVendors[];
	stores: IVendorUserStores[];
}

// ! CRUD

export interface IVendorUserPayload {
	id?: IVendorUserListItemResponse['id'];
	first_name: IVendorUserListItemResponse['first_name'];
	last_name: IVendorUserListItemResponse['last_name'];
	type: IVendorUserListItemResponse['type'];
	email: IVendorUserListItemResponse['email'];
	phone_number: IVendorUserListItemResponse['phone_number'];
	country_code: IVendorUserListItemResponse['country_code'];
	language: IVendorUserListItemResponse['language'];

	password?: string;
}
