import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESearchEngineTabsKeys } from './types';
import { SEARCH_ENGINE_ROUTES_TAB_ROUTES } from './Tabs/routes.search-engine-tabs';
import { SearchOutlined } from '@ant-design/icons';

const SearchEngineListPageLayout = lazy(() => import('pages/Analytics/SearchEngine/SearchEngineListPageLayout'));

export const SEARCH_ENGINE_ROUTES: TRouteObject = {
	path: 'search-engine',
	element: <SearchEngineListPageLayout />,
	handle: {
		showOnNav: true,
		icon: <SearchOutlined />,
		translationKey: 'search_engine',
		permissions: [APP_PERMISSIONS.analytics.search_engine.view],
	},
	children: [
		{
			index: true,
			element: (
				<Navigate
					to={ESearchEngineTabsKeys.NOT_HIT_VENDOR_SEARCHES}
					replace
				/>
			),
		},
		...SEARCH_ENGINE_ROUTES_TAB_ROUTES,
	],
};
