import { GeoJsonProperties } from 'geojson';
import Supercluster, { ClusterProperties } from 'supercluster';

export const DEFAULT_DRIVER_MARKER_SIZE = 44;
export const DEFAULT_BRANCH_MARKER_SIZE = 36;

export const INITIAL_MAP_ZOOM: number = 13;

export const LIVE_TRACK_POLYGON_OPTIONS: google.maps.PolygonOptions = {
	fillOpacity: 0.2,
	strokeOpacity: 1,
	strokeWeight: 2,
	zIndex: 1,
};

export const SUPERCLUSTER_OPTIONS: Supercluster.Options<GeoJsonProperties, ClusterProperties> = {
	extent: 128,
	radius: 40,
	minZoom: 12,
	maxZoom: 40,
};
