import { FC, useEffect } from 'react';
import { IMapEventManagerProps } from './types';
import { useMap } from '@vis.gl/react-google-maps';

export const MapEventManager: FC<IMapEventManagerProps> = ({ ...props }) => {
	const map = useMap();
	const gme = google.maps.event;

	useEffect(() => {
		if (!map) return;

		const listeners: Array<google.maps.MapsEventListener> = [];

		if (props.onClick) {
			listeners.push(map.addListener('click', props.onClick));
		}
		if (props.onRightClick) {
			listeners.push(map.addListener('rightclick', props.onRightClick));
		}

		return () => {
			listeners.forEach((listener) => {
				listener.remove();
			});
		};
	}, [gme, map, props.onClick, props.onRightClick]);

	return <></>;
};
