import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITimingsDayProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import TimingsRange from '../TimingsRange';
import { MAX_SHIFTS_PER_DAY } from '../config';
import styles from './TimingsDay.module.css';
import { Button, Flex } from 'antd';

const TimingsDay: FC<ITimingsDayProps> = ({
	day,
	schedule,
	isEdit = true,
	onTimeChange,
	onAddScheduleItem,
	onRemoveScheduleItem,
	showDayTitle = false,
}) => {
	const { t: tVendorTimings } = useTranslation('vendors', { keyPrefix: 'vendor_details.timings' });

	const hasMaxScheduleSegments = schedule.length >= MAX_SHIFTS_PER_DAY;

	// ! render
	return (
		<div className={styles.day_wrap}>
			{/* TITLE */}
			{showDayTitle && (
				<div className={styles.title_wrap}>
					<span className={styles.title}>
						<TranslatedLabel
							i18nKey={day}
							extra={{ keyPrefix: 'weekdays' }}
						/>
					</span>
				</div>
			)}

			{/* SCHEDULE ITEMS */}
			<Flex
				vertical
				gap={8}
			>
				<>
					{schedule.map((scheduleItem, index) => (
						<TimingsRange
							key={`${day}-${index}`}
							scheduleItem={scheduleItem}
							isEdit={isEdit}
							onRemove={() => onRemoveScheduleItem(index)}
							onTimeChange={(timeType, timeValue) => onTimeChange(index, timeType, timeValue)}
						/>
					))}
				</>

				{schedule?.length === 0 && !isEdit && <div>{tVendorTimings('closed')}</div>}

				{/* ADD BUTTON */}
				{isEdit && (
					<div>
						<Button
							size='small'
							disabled={hasMaxScheduleSegments}
							title={tVendorTimings(hasMaxScheduleSegments ? 'actions.add.warning' : 'actions.add.hours')}
							onClick={() => onAddScheduleItem(day)}
						>
							{`+ ${tVendorTimings('actions.add.hours')}`}
						</Button>
					</div>
				)}
			</Flex>
		</div>
	);
};

export default TimingsDay;
