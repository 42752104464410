import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import useBranch from 'hooks/useBranch';
import useVendor from 'hooks/useVendor';
import Spinner from 'components/Spinner';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import IntegrationAlert from '../../SharedComponents/IntegrationAlert';
import { AssociatedMenuModal } from './AssociatedMenuModal';
import { Alert, Button, Flex } from 'antd';

const BranchMenusTabContent: FC = () => {
	const navigate = useNavigate();
	const { vendorId, integrationType } = useVendor();
	const { branchId, data: branchData, isLoading: branchLoading, setBranchMenuId } = useBranch();

	const { t: tBranchMenu } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.menus',
	});

	const [openAssociateMenuModal, setOpenAssociateMenuModal] = useState(false);

	// ! selectors
	const integrationsSettings = useSelector(getIntegrationTypeSettings(integrationType));

	// ! handlers
	const navigateToMenu = (menuId: number) => {
		// todo @nair @tiagoloureiro - REVIEW & possible REFACTOR
		navigate(`${menuId}`, { replace: true });
	};

	const closeAssociateMenuModal = () => setOpenAssociateMenuModal(false);

	const onConfirmMenuAssociation = (newAssociatedMenuId: number) => {
		closeAssociateMenuModal();

		setBranchMenuId(newAssociatedMenuId);
		navigateToMenu(newAssociatedMenuId);
	};

	// ! effects
	useEffect(() => {
		if (!branchData?.menu_id) return;

		navigateToMenu(branchData?.menu_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchData?.menu_id]);

	// ! renders
	if (!integrationsSettings || branchLoading || branchData?.menu_id) {
		return <Spinner defaultAntdSpinner />;
	}

	// Render Branch Menu
	return (
		<>
			<Flex
				vertical
				gap='small'
			>
				{integrationsSettings.isIntegration && <IntegrationAlert pageType={EVendorTabsKeys.MENUS} />}

				{integrationsSettings.tabs_access?.[EVendorTabsKeys.MENUS] && (
					<Alert
						showIcon
						type='warning'
						message={
							<Flex gap='large'>
								{tBranchMenu('messages.no_menu.warning.title')}
								<Button
									size='small'
									onClick={() => setOpenAssociateMenuModal(true)}
								>
									{tBranchMenu('actions.associate_menu')}
								</Button>
							</Flex>
						}
					/>
				)}
			</Flex>

			<AssociatedMenuModal
				vendorId={vendorId}
				branchId={branchId}
				open={openAssociateMenuModal}
				onCloseModal={closeAssociateMenuModal}
				onAfterConfirm={onConfirmMenuAssociation}
			/>
		</>
	);
};

export default BranchMenusTabContent;
