import { THttpRequestConfig } from 'hooks';

export const ANALYTICS_API = {
	getNotHitVendorSearches: (search?: string): THttpRequestConfig => ({
		url: 'operations/analytics/search-engine/no-hit-searches',
		method: 'GET',
		params: {
			search,
		},
	}),

	getTopHitVendorSearches: (search?: string): THttpRequestConfig => ({
		url: 'operations/analytics/search-engine/top-hit-searches',
		method: 'GET',
		params: {
			search,
		},
	}),
};
