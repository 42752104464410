import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { IBranchTimingsSchedule } from 'types/api';
import { Nullable } from 'types/common';
import { TTimeType } from '../types';
import { IApplyToAllModalHandle, IApplyToAllModalProps } from './types';
import TimingsDay from '../TimingsDay';
import { ToStringDate } from '../TimingsRange/helpers';
import { emptyScheduleItem } from '../config';
import dayjs from 'dayjs';
import { Modal } from 'antd';

const ApplyToAllModal = forwardRef<IApplyToAllModalHandle, IApplyToAllModalProps>(({ onOk }, ref) => {
	const { t: tCommon } = useTranslation();
	const { t: tWorkShifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	// ! states
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const [schedule, setSchedule] = useState<IBranchTimingsSchedule[]>([emptyScheduleItem]);

	// ! handlers
	const handleConfirm = () => {
		onOk(schedule);
		onClose();
	};

	const onOpen = () => {
		setIsOpen(true);
	};
	const onClose = () => {
		setIsOpen(false);
	};

	const onCancel = () => {
		onClose();
	};

	// * state manipulation
	const removeScheduledItemWithConfirmation = (index: number) => {
		setSchedule((prev) => {
			const filteredScheduled = prev.filter((_, scheduleIndex) => scheduleIndex !== index);

			return filteredScheduled;
		});
	};

	const addScheduleItemWithConfirmation = () => {
		setSchedule((prev) => [...prev, emptyScheduleItem]);
	};

	const timeChangeWithConfirmation = (index: number, timeType: TTimeType, timeValue: Nullable<dayjs.Dayjs>) => {
		setSchedule((prev) => {
			const schedule = prev.map((scheduleItem, scheduleIndex) => {
				if (scheduleIndex !== index) return scheduleItem;

				return {
					...scheduleItem,
					[timeType]: ToStringDate(timeValue, timeType),
				};
			});

			return schedule;
		});
	};

	// ! ref hook
	useImperativeHandle(
		ref,
		() => {
			const api: IApplyToAllModalHandle = {
				isOpen,
				open: onOpen,
				close: onClose,
			};

			return api;
		},
		[isOpen]
	);

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={isOpen}
			title={tWorkShifts('apply_to_all_modal.title')}
			okText={tCommon('action_buttons.continue')}
			onOk={handleConfirm}
			onCancel={onCancel}
		>
			<TimingsDay
				day='ALL'
				schedule={schedule}
				onTimeChange={timeChangeWithConfirmation}
				onAddScheduleItem={addScheduleItemWithConfirmation}
				onRemoveScheduleItem={removeScheduledItemWithConfirmation}
			/>
		</Modal>
	);
});

export default ApplyToAllModal;
