import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

// ! LIVE_TRACKING_UPDATE_TIME_OPTIONS
export const LIVE_TRACKING_UPDATE_INTERVAL_DEFAULT_VALUE = 3;

const updateOptionsValuesAsSeconds = [LIVE_TRACKING_UPDATE_INTERVAL_DEFAULT_VALUE, 9, 15, 30];

export const LIVE_TRACKING_UPDATE_INTERVAL_OPTIONS: ISelectOption[] = updateOptionsValuesAsSeconds.map((value) => ({
	value,
	label: (
		<TranslatedLabel
			extra={{ number: value }}
			nameSpace='live-tracking'
			i18nKey='page_settings.information_update_interval.interval_seconds'
		/>
	),
}));

export const LIVE_TRACKING_UPDATE_INTERVAL_STORAGE_KEY = 'live_tracking_update_interval_data';
