import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertTimeSlotToHours, generateTimeSlotsInMinutes, transformTimingsDataToWorkShifts } from './helper';
import { Nullable, TShortWeekDay } from 'types/common';
import { IBranchTimingConvertedToNumbers, IBranchTimingsScheduleConverted } from './types';
import { MAX_SHIFTS_PER_DAY, WEEK_DAYS } from '../config';
import { IWorkshiftController } from '../useWorkshift';
import { IWorkShiftDayTimeModalHandle, WorkShiftDayTimeModal } from './WorkShiftDayTimeModal';
import styles from './WorkshiftsTableEditor.module.css';
import { Alert, Space, Tooltip } from 'antd';

interface IWorkshiftTableEditorProps {
	workshiftController: IWorkshiftController;
	isEdit: boolean;
}

const WorkshiftTableEditor: FC<IWorkshiftTableEditorProps> = ({ workshiftController, isEdit }) => {
	const { t: tWorkshifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	// ! states
	const [initialTimeSlot, setInitialTimeSlot] = useState<Nullable<number>>(null);
	const [finalTimeSlot, setFinalTimeSlot] = useState<Nullable<number>>(null);

	const [isLimitWarningDisplay, setLimitWarningDisplay] = useState<boolean>(false);

	// ! refs
	const timeEditModalRef = useRef<IWorkShiftDayTimeModalHandle>(null);

	// ! handlers
	const TIME_SLOTS_IN_MINUTES: number[] = generateTimeSlotsInMinutes();

	const convertedTimingsData = useMemo<Record<TShortWeekDay, IBranchTimingConvertedToNumbers>>(() => {
		return transformTimingsDataToWorkShifts(workshiftController.timingsData);
	}, [workshiftController.timingsData]);

	const getSlotCurrentOpenStatus = (scheduleInMinutes: IBranchTimingsScheduleConverted[], slot: number) => {
		const isOpen = scheduleInMinutes.find(
			(item) => item.rounded_open_time <= slot && item.rounded_close_time > slot
		);

		return !!isOpen;
	};

	// Mouse Events
	const handleMouseEnter = (slot: number) => {
		if (initialTimeSlot === null) return;

		setFinalTimeSlot(slot);
	};

	const handleMouseDown = (day: TShortWeekDay, slot: number) => {
		workshiftController.setSelectedDay(day);

		setInitialTimeSlot(slot);
	};

	const handleMouseUp = (day: TShortWeekDay, slot: number) => {
		if (finalTimeSlot === null || finalTimeSlot === initialTimeSlot) {
			timeEditModalRef.current?.open(workshiftController.timingsData[day]);
		}

		if (initialTimeSlot !== null && finalTimeSlot !== null && finalTimeSlot !== initialTimeSlot) {
			const [openTimeSlot, closeTimeSlot] = [initialTimeSlot, slot].sort((a, b) => a - b);

			const scheduleInMinutes: IBranchTimingsScheduleConverted[] = convertedTimingsData[day].schedule;

			try {
				workshiftController.toggleSlot(
					day,
					openTimeSlot,
					closeTimeSlot,
					scheduleInMinutes,
					getSlotCurrentOpenStatus(scheduleInMinutes, openTimeSlot)
				);
			} catch (error) {
				setLimitWarningDisplay(true);
			} finally {
			}
		}

		// clear
		setFinalTimeSlot(null);
		setInitialTimeSlot(null);
	};

	const isPaintingNewWorkshift = initialTimeSlot;

	// ! render
	return (
		<>
			<Space
				direction='vertical'
				size='large'
				className='w-100'
			>
				{isLimitWarningDisplay && (
					<Alert
						type='warning'
						showIcon
						message={tWorkshifts('errors.more_than_three_workshifts', { limit: MAX_SHIFTS_PER_DAY })}
					/>
				)}
				<table className={styles.table}>
					<thead>
						<tr className={styles.table_header_row}>
							<th></th>
							{WEEK_DAYS.map((day) => (
								<th
									key={day}
									className={styles.table_header_weekday}
								>
									{day}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{TIME_SLOTS_IN_MINUTES.map((slot) => {
							return (
								<tr key={slot}>
									<td>
										<div
											className={styles.table_hours_column}
											data-time={convertTimeSlotToHours(slot)}
										/>
									</td>

									{WEEK_DAYS.map((day) => {
										const isOpenSlot = getSlotCurrentOpenStatus(
											convertedTimingsData[day].schedule,
											slot
										);

										const tooltipTitleTimesGroup = convertedTimingsData[day].schedule.find(
											({ open_time, close_time }) => open_time <= slot && close_time > slot
										);

										const isCellPrePainted =
											finalTimeSlot !== null &&
											initialTimeSlot !== null &&
											day === workshiftController.selectedDay &&
											((finalTimeSlot <= slot && slot <= initialTimeSlot) ||
												(initialTimeSlot <= slot && slot <= finalTimeSlot));

										const renderCell = (
											<td
												key={`${slot}-${day}`}
												onMouseEnter={() => (isEdit ? handleMouseEnter(slot) : undefined)}
												onMouseDown={() => (isEdit ? handleMouseDown(day, slot) : undefined)}
												onMouseUp={() => (isEdit ? handleMouseUp(day, slot) : undefined)}
												className={[
													styles.table_cells,
													isOpenSlot ? styles.table_cells_open : styles.table_cells_close,
													isCellPrePainted ? styles.pre_paint : '',
												].join(' ')}
											/>
										);

										if (isPaintingNewWorkshift) {
											return renderCell;
										}

										return (
											<Tooltip
												key={`tooltip-${slot}-${day}`}
												placement='right'
												title={
													tooltipTitleTimesGroup
														? `${convertTimeSlotToHours(
																tooltipTitleTimesGroup.open_time
														  )} - ${convertTimeSlotToHours(
																tooltipTitleTimesGroup.close_time
														  )}`
														: undefined
												}
											>
												{renderCell}
											</Tooltip>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</Space>

			<WorkShiftDayTimeModal
				ref={timeEditModalRef}
				workshiftController={workshiftController}
			/>
		</>
	);
};

export default WorkshiftTableEditor;
