import { FC, memo } from 'react';
import { IExclamationCircleProps } from './types';

const ExclamationCircle: FC<IExclamationCircleProps> = ({ color }) => {
	return (
		<svg
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.61035 0C3.74635 0 0.610352 3.136 0.610352 7C0.610352 10.864 3.74635 14 7.61035 14C11.4744 14 14.6104 10.864 14.6104 7C14.6104 3.136 11.4744 0 7.61035 0ZM8.31035 10.5H6.91035V9.1H8.31035V10.5ZM8.31035 7.7H6.91035V3.5H8.31035V7.7Z'
				fill={color}
			/>
		</svg>
	);
};

export default memo(ExclamationCircle);
