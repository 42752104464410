import { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useSelector } from 'store';
import { getSelectedCountry, getSettingsSlice } from 'store/selectors';
import { useAuth, useGlobalConfigs, useHandleErrors } from 'hooks';
import { serializeFiltersSettings } from './helpers';
import { throttle } from 'utils/throttle';
import { createDriverTrackingSocket } from 'configs/api';
import {
	EHttpStatus,
	ILiveTrackingAuthSuccess,
	ILiveTrackingDriversOnline,
	ILiveTrackingMetrics,
	ILiveTrackingSettings,
	ILiveTrackingSettingsEvent,
	ISocketEventErrorResponse,
	TLiveTrackingDriversData,
} from 'types/api';
import { Nullable } from 'types/common';
import { DASHBOARD_DRIVERS_LIVE_TRACKING_EVENT } from './types';
import {
	LIVE_TRACKING_UPDATE_INTERVAL_DEFAULT_VALUE,
	LIVE_TRACKING_UPDATE_INTERVAL_STORAGE_KEY,
} from 'pages/LiveTracking/config';
import { App } from 'antd';
import { Socket } from 'socket.io-client';

const TIME_OFF_SET_ALLOWANCE = 1000;

const useDriverLiveTrackSocket = () => {
	const { t: tLiveTracking } = useTranslation('live-tracking');
	const { message } = App.useApp();
	const auth = useAuth();
	const { configs, storage } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	// filters
	const [driverFiltersSettings, setDriverFiltersSettings] = useState<Nullable<ILiveTrackingSettings>>(null);

	// driver
	const [driversMetrics, setDriversMetrics] = useState<Nullable<ILiveTrackingMetrics>>(null);
	const [driversData, setDriversData] = useState<Nullable<TLiveTrackingDriversData>>(null);

	// socket
	const [socketLoading, setSocketLoading] = useState(true);

	// ! refs
	const handleProcessData = (driversData: ILiveTrackingDriversOnline) => {
		setDriversMetrics(driversData.metrics);
		setDriversData(driversData.drivers);
	};

	const processDataCallBack = useRef<Dispatch<ILiveTrackingDriversOnline>>(
		throttle(handleProcessData, LIVE_TRACKING_UPDATE_INTERVAL_DEFAULT_VALUE * 1000 - TIME_OFF_SET_ALLOWANCE)
	);

	// ! selectors
	const selectedCountry = useSelector(getSelectedCountry);
	const { language: currentAppLanguage } = useSelector(getSettingsSlice);

	// ! memos
	// Prevent potential bugs that may occur if 'driversData' is accessed before settings are set.
	const delayedDriverData = useMemo(() => {
		if (!driverFiltersSettings) return null;

		return driversData;
	}, [driverFiltersSettings, driversData]);

	// ! helpers
	const resetSocket = (currentSocket: Socket) => {
		currentSocket.removeAllListeners();
		currentSocket.disconnect();
	};

	// ! handlers
	// connection related
	const onDriverSocketConnectError = (error?: Error) => {
		setSocketLoading(false);
		console.error(error);
		const key = Date.now();

		message.error({
			content: tLiveTracking('error_messages.connection_error'),
			duration: 5,
			key,
			onClick: () => message.destroy(key),
		});
	};

	// driver related
	const onOnlineDrivers = (driversData: ILiveTrackingDriversOnline | ISocketEventErrorResponse) => {
		if ('error' in driversData) {
			message.error({
				content: tLiveTracking('error_messages.online_drivers_error'),
				duration: 5,
			});
			return;
		}

		processDataCallBack.current({ ...driversData });
	};

	const onAuthCheck = (currentSocket: Socket) => (data: ISocketEventErrorResponse | ILiveTrackingAuthSuccess) => {
		if (data.status === EHttpStatus.OK) return;

		const customError = {
			data: { message: tLiveTracking('error_messages.auth_failed') },
			status: data.status,
		} as AxiosResponse;

		handleError(customError, true);

		resetSocket(currentSocket);
		setDriversData(null);
		setDriversMetrics(null);
	};

	const onSettings = (settings: ILiveTrackingSettingsEvent) => {
		if ('error' in settings) {
			const key = Date.now();

			message.error({
				content: tLiveTracking('error_messages.filters_settings_not_found'),
				duration: 5,
				key,
				onClick: () => message.destroy(key),
			});

			return;
		}

		/** Serialize backend data
		 * 1) add icons to vehicle filters
		 * 2) add icons to delivery_status filters
		 * 3) add icons to capacity_status filters
		 */
		const serializedSettings = serializeFiltersSettings({
			settings: settings,
			statusToColor: settings.status_color,
		});
		setDriverFiltersSettings(serializedSettings);
	};

	const onChangeIntervalUpdate = (time: number) => {
		storage.set(LIVE_TRACKING_UPDATE_INTERVAL_STORAGE_KEY, time);

		processDataCallBack.current = throttle(handleProcessData, time * 1000 - TIME_OFF_SET_ALLOWANCE);
	};

	// ! effects

	useEffect(
		() => {
			if (!auth.user?.access_token || !selectedCountry?.iso_two_code) return;
			// socket init
			const socket = createDriverTrackingSocket({
				baseUrl: configs.ws_url,
				token: auth.user.access_token,
				countryCode: selectedCountry.iso_two_code,
				language: currentAppLanguage,
			});

			//  listeners
			socket.on(DASHBOARD_DRIVERS_LIVE_TRACKING_EVENT.CONNECT_ERROR, onDriverSocketConnectError);

			// subscribe for drivers event
			socket.on(DASHBOARD_DRIVERS_LIVE_TRACKING_EVENT.DRIVERS_ONLINE, onOnlineDrivers);

			socket.on(DASHBOARD_DRIVERS_LIVE_TRACKING_EVENT.SETTINGS, onSettings);

			socket.on(DASHBOARD_DRIVERS_LIVE_TRACKING_EVENT.AUTH_CHECK, onAuthCheck(socket));

			setSocketLoading(true);
			socket.connect();

			// remove socket events on destroy
			return () => {
				resetSocket(socket);
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[auth, configs.ws_url, currentAppLanguage, selectedCountry]
	);

	// turn off loading, when have all info to render
	useEffect(() => {
		if (!driverFiltersSettings || !driversData) {
			return;
		}

		setSocketLoading(false);
	}, [driverFiltersSettings, driversData]);

	return {
		driversData: delayedDriverData,
		socketLoading,
		driverFiltersSettings,
		driversMetrics,
		changeTimeInterval: onChangeIntervalUpdate,
	};
};

export default useDriverLiveTrackSocket;
