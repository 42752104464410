import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CAR_VEHICLE_TYPE_ICON, MOTORCYCLE_VEHICLE_TYPE_ICON } from 'configs/common';
import { EStoreDeliveryAreaVehicleType } from 'types/api';
import TranslatedLabel from 'components/TranslatedLabel';
import { Flex, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { GlobalOutlined } from '@ant-design/icons';

const ICONS: Record<EStoreDeliveryAreaVehicleType, JSX.Element> = {
	[EStoreDeliveryAreaVehicleType.MOTORCYCLE]: MOTORCYCLE_VEHICLE_TYPE_ICON,
	[EStoreDeliveryAreaVehicleType.CAR]: CAR_VEHICLE_TYPE_ICON,
	[EStoreDeliveryAreaVehicleType.ALL]: <GlobalOutlined />,
};

export const AREA_VEHICLE_TYPE_OPTIONS = Object.values(EStoreDeliveryAreaVehicleType).map((type) => ({
	label: (
		<Flex
			align='center'
			gap={12}
		>
			{ICONS[type]}
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey={type}
				extra={{
					keyPrefix:
						'vendor_details.tabs.branches.branch_details.tabs.delivery_area.area_vehicle_type.enum_vehicle_types',
				}}
			/>
		</Flex>
	),
	value: type,
}));

interface IAreaVehicleTypeSelectorProps<T = EStoreDeliveryAreaVehicleType> {
	selectedVehicleType?: T;
	disabled?: boolean;
	translationsKey?: string;
	onChange: (value: T, option?: DefaultOptionType | DefaultOptionType[]) => void;
}

export const AreaVehicleTypeSelector: FC<IAreaVehicleTypeSelectorProps> = ({
	selectedVehicleType,
	disabled = false,
	onChange,
}) => {
	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area.area_vehicle_type',
	});

	return (
		<Flex
			gap={6}
			vertical
		>
			<Typography.Text>{tVendorBranchDeliveryArea('label')}:</Typography.Text>
			<Select<EStoreDeliveryAreaVehicleType>
				size='small'
				disabled={disabled}
				defaultValue={selectedVehicleType}
				options={AREA_VEHICLE_TYPE_OPTIONS}
				onChange={onChange}
			/>
		</Flex>
	);
};
