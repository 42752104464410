import { Property } from 'csstype';
import { ELiveTrackingDeliveryStatus, ILiveTrackingDriver, TLiveTrackingStatusToColor } from 'types/api';

export const calcZRotate = (rotateAngle: number) => rotateAngle + 90;

export const getDriverIconColors = (
	driver: ILiveTrackingDriver,
	driverDeliveryStatusFilters: ELiveTrackingDeliveryStatus[],
	statusToColor: TLiveTrackingStatusToColor
): {
	color: Property.Color;
	ordersColors: Property.Color[];
} => {
	let ordersColors: Property.Color[] = [];
	let color: Property.Color = statusToColor[driver.capacity_status];

	// no orders - default settings
	if (!driver.orders?.length) {
		return {
			...driver,
			color,
			ordersColors,
		};
	}

	// one order - driver is order status color, if filter is selected
	if (driver.orders?.length === 1 && driverDeliveryStatusFilters.includes(driver.orders[0].delivery_status)) {
		color = statusToColor[driver.orders[0].delivery_status];
		return {
			...driver,
			color,
			ordersColors,
		};
	}

	// multiple orders - driver is capacity status color, and we have add stripes
	ordersColors = driver.orders.map(({ delivery_status }) => {
		if (driverDeliveryStatusFilters.includes(delivery_status)) {
			return statusToColor[delivery_status];
		} else {
			return color;
		}
	});

	return {
		...driver,
		color,
		ordersColors,
	};
};
