import { CSSProperties, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ECustomerSupportRequestStatus } from 'types/api';
import {
	EAssignmentMode,
	EDeliveryMode,
	EFileType,
	EStatus,
	ESupportedCountriesIsoTwoCodes,
	ESupportedLanguages,
	EVehicleTypes,
	IFilterItem,
	ISelectOption,
	ISupportedLanguage,
} from 'types/common';
import { THourRanges } from 'components/HoursFilterGroup';
import TranslatedLabel from 'components/TranslatedLabel';
import { DescriptionsProps, ModalProps, Tooltip } from 'antd';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';
import { CarOutlined } from '@ant-design/icons';
import noImgLogo from 'assets/images/no-image.svg';
import motorcycleIcon from 'assets/svgs/motorcycle.svg';

export const AREAS_ID_STORAGE_KEY = 'selected_areas_ids';
export const TIMEZONE_STORAGE_KEY = 'selected_timezone';
export const APP_LANGUAGE_STORAGE_KEY = 'operations_dashboard_selected_app_language';
export const DISPATCHER_REFRESH_STORAGE_KEY = 'dispatcher_refresh';

export const ID_COLUMN_WIDTH = 100;

export const SEARCH_DEBOUNCE_TIMER = 500; // ms

export const DEFAULT_EMPTY_VALUE_PLACEHOLDER = '--';

export const FILE_FORMAT_NAMES: Record<EFileType, string> = {
	[EFileType.JPEG]: 'JPEG',
	[EFileType.PNG]: 'PNG',
	[EFileType.PDF]: 'PDF',
};

// ! google maps
export const DEFAULT_MAP_CENTER: google.maps.LatLngLiteral = {
	lat: 29.35799231499003,
	lng: 47.98062606763254,
};

export const LAT_REGEX_PATTERN = /^(\+|-)?(?:90(?:(?:\.0+)?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]+)?))$/;
export const LNG_REGEX_PATTERN = /^(\+|-)?(?:180(?:(?:\.0+)?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]+)?))$/;

export const getGoogleMapsExternalLink = (latLng: string): string => {
	return `https://www.google.com/maps/search/?api=1&query=${latLng}`;
};

// ! filters
export const DEFAULT_HOURS_FILTERS: THourRanges[] = ['24', '48', '72', '168'];

// ! descriptions
export const DEFAULT_DESCRIPTIONS_PROPS: DescriptionsProps = {
	column: 1,
	size: 'small',
	styles: { label: { fontWeight: 600 } },
};

// ! modals
export const DEFAULT_MODAL_PROPS: ModalProps = {
	centered: true,
	getContainer: false,
	maskClosable: false,
	okText: <TranslatedLabel i18nKey='action_buttons.save' />,
	cancelText: <TranslatedLabel i18nKey='action_buttons.cancel' />,
};

// ! tags
export const DEFAULT_TAG_STYLES: CSSProperties = {
	userSelect: 'none',
	borderRadius: '4px',
	display: 'inline-flex',
	alignItems: 'center',
};

// ! tabs
export const DEFAULT_TABS_PROPS: CSSProperties = {
	height: '100%',
	overflowY: 'auto',
	overflowX: 'hidden',
};

// ! forms
export const DEFAULT_FORM_ITEMS_LAYOUT = {
	labelCol: { span: 4 },
	wrapperCol: { span: 12 },
};

export const COL_RESPONSIVE_CONFIG = {
	xs: 24,
	lg: 12,
};

// ! Google Maps styles
export const GLOBAL_MAP_CONTAINER_STYLE: CSSProperties = {
	width: '100%',
	height: '100%',
	border: '1px solid var(--border-color)',
	position: 'relative',
};

// ! sort options
export const SORT_ORDER_OPTIONS = [...Array(500).keys()].map<ISelectOption>((num) => ({
	label: `${num}`,
	value: num,
}));

// ! status options
export const STATUS_SELECT_OPTIONS: ISelectOption[] = Object.values(EStatus).map((status) => ({
	value: status,
	label: (
		<TranslatedLabel
			i18nKey={status}
			extra={{ keyPrefix: 'on_off_switch' }}
		/>
	),
}));

export const STATUS_FILTERS_CONFIG: IFilterItem[] = Object.values(EStatus).map((value) => ({
	value,
	text: (
		<TranslatedLabel
			i18nKey={value}
			extra={{ keyPrefix: 'on_off_switch' }}
		/>
	),
}));

export const STATUS_NEW_VALUE_CONFIG: Record<EStatus, EStatus> = {
	[EStatus.ACTIVE]: EStatus.INACTIVE,
	[EStatus.INACTIVE]: EStatus.ACTIVE,
};

// ! phone-number strength
export const PHONE_NUMBER_REGEX_PATTERN = /^(?:[0-9] ?){6,14}[0-9]$/;

// ! password strength
export const PASSWORD_REGEX_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

// ! table image cell
export const ImageCell: FC<{ imageSrc?: string }> = ({ imageSrc }) => {
	const { t: tCommonImage } = useTranslation('common', { keyPrefix: 'image' });
	if (!imageSrc)
		return (
			<div style={{ textAlign: 'center' }}>
				<Tooltip title={tCommonImage('no_image')}>
					<img
						src={noImgLogo}
						alt=''
						width={22}
						height={22}
					/>
				</Tooltip>
			</div>
		);

	const overlay = (
		<img
			loading='lazy'
			src={imageSrc}
			alt={tCommonImage('alt_missing')}
			style={{ maxWidth: '150px', maxHeight: '150px' }}
		/>
	);

	return (
		<Tooltip overlay={overlay}>
			<img
				loading='lazy'
				src={imageSrc}
				alt={tCommonImage('alt_missing')}
				style={{ maxWidth: '50px', maxHeight: '50px', objectFit: 'cover', color: 'var(--info-color)' }}
			/>
		</Tooltip>
	);
};

// ! CARD WRAPPER fOR IMAGE BOX
export const CARD_IMG_BODY_STYLES: CSSProperties = {
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

// ! order delivery mode
export const DELIVERY_MODE_OPTIONS = (isUAE: boolean): ISelectOption[] =>
	Object.values(EDeliveryMode)
		.filter((value) => value !== EDeliveryMode.ZONE_DELIVERY_SERVICES || isUAE)
		.map((value) => ({
			value,
			label: (
				<TranslatedLabel
					i18nKey={value}
					extra={{ keyPrefix: 'delivery_mode.labels' }}
				/>
			),
		}));

// ! order assignment mode
export const ASSIGNMENT_MODE_OPTIONS: ISelectOption[] = Object.values(EAssignmentMode).map((value) => ({
	value,
	label: (
		<TranslatedLabel
			i18nKey={value}
			extra={{ keyPrefix: 'assignment_mode.labels' }}
		/>
	),
}));

// ! languages
export const SUPPORTED_LANGUAGES: Record<ESupportedLanguages, ISupportedLanguage> = {
	[ESupportedLanguages.ENGLISH]: {
		name: 'English',
		iso_two_code: ESupportedLanguages.ENGLISH,
		flag: 'https://cdn.ipregistry.co/flags/noto/us.png',
		defaultDirection: 'ltr',
		locale: enUS,
	},
	[ESupportedLanguages.ARABIC]: {
		name: 'Arabic',
		iso_two_code: ESupportedLanguages.ARABIC,
		flag: 'https://cdn.ipregistry.co/flags/noto/kw.png',
		defaultDirection: 'rtl',
		locale: arEG,
	},
};

export const LANGUAGES_SELECT_OPTIONS: ISelectOption[] = Object.values(SUPPORTED_LANGUAGES).map((language) => ({
	value: language.iso_two_code,
	label: (
		<TranslatedLabel
			nameSpace='common'
			i18nKey={`languages.${language.iso_two_code}`}
		/>
	),
}));

// ! countries
export const COUNTRIES_CODES_TO_ID_MAP: Record<ESupportedCountriesIsoTwoCodes, number> = {
	kw: 73,
	sa: 116,
	ae: 141,
	pt: 110,
};

// ! Customer Support Requests
export const CUSTOMER_SUPPORT_REQUESTS_STATUS_FILTERS_CONFIG: IFilterItem[] = Object.values(
	ECustomerSupportRequestStatus
).map((value) => ({
	value,
	text: (
		<TranslatedLabel
			i18nKey={value}
			nameSpace='customer-support-requests'
			extra={{ keyPrefix: 'status' }}
		/>
	),
}));

// ! CARI UNLIMITED
export const CARI_UNLIMITED_COUNTRIES_WHITE_LIST = [
	ESupportedCountriesIsoTwoCodes.KUWAIT,
	ESupportedCountriesIsoTwoCodes.UAE,
];

// ! Vehicle Types
export const VEHICLE_TYPES_OPTIONS: ISelectOption[] = Object.values(EVehicleTypes).map((type) => ({
	value: type,
	label: (
		<TranslatedLabel
			nameSpace='common'
			i18nKey={`vehicle_types.${type}`}
		/>
	),
}));

export const CAR_VEHICLE_TYPE_ICON = <CarOutlined />;

export const MOTORCYCLE_VEHICLE_TYPE_ICON = (
	<img
		src={motorcycleIcon}
		alt='motorcycle-icon'
		width={14}
		height={14}
	/>
);

export const VEHICLE_TYPES_ICONS_MAP: Record<EVehicleTypes, ReactNode> = {
	[EVehicleTypes.CAR]: CAR_VEHICLE_TYPE_ICON,
	[EVehicleTypes.MOTORCYCLE]: MOTORCYCLE_VEHICLE_TYPE_ICON,
};
