import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { IAreaInfoWindowProps } from './types';
import { getAreaCenter } from 'components/Areas/helper';
import DisplayNameAndNameAr from 'components/DisplayNameAndNameAr';
import { Button, Flex, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { InfoWindow, useMap } from '@vis.gl/react-google-maps';

const AreaInfoWindow: FC<IAreaInfoWindowProps> = (props) => {
	const { t: tCommon } = useTranslation('common');
	const { area, isSelected, isEditing, extra, onHideArea, onSelectArea } = props;

	const visMap = useMap();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! memos
	const getPolygonCentroid = getAreaCenter(area.polygon?.coordinates, area.center);

	// ! render
	const InfoWindowHeader = () => (
		<Flex
			gap={12}
			align='center'
		>
			<Typography.Text strong>{`#${area.id}`}</Typography.Text>
			<NavLink
				to={generatePath(ABSOLUTE_ROUTES.MANAGEMENT_AREA_DETAILS, {
					countryIsoTwoCode,
					areaId: area.id,
				})}
			>
				<Button
					size='small'
					type='dashed'
					icon={<EyeOutlined />}
				/>
			</NavLink>
		</Flex>
	);

	return (
		<InfoWindow
			disableAutoPan
			headerContent={<InfoWindowHeader />}
			position={getPolygonCentroid}
			{...props}
		>
			<div style={{ minWidth: 100, maxWidth: 'fit-content' }}>
				{!visMap && (
					<div style={{ position: 'absolute', top: 14 }}>
						{/*  WORK around for title placement of InfoWindow */}
						<InfoWindowHeader />
					</div>
				)}

				<Flex
					vertical
					gap={12}
				>
					<DisplayNameAndNameAr
						name={area.name}
						nameAr={area.name_ar}
					/>

					{isEditing && (
						<Flex gap='small'>
							<Button
								block
								size='small'
								icon={<EyeInvisibleOutlined />}
								onClick={onHideArea}
							>
								{tCommon('action_buttons.hide')}
							</Button>

							<Button
								block
								size='small'
								type={isSelected ? 'dashed' : 'default'}
								icon={isSelected ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
								onClick={onSelectArea}
							>
								{isSelected ? tCommon('action_buttons.remove') : tCommon('action_buttons.add')}
							</Button>
						</Flex>
					)}

					{extra}
				</Flex>
			</div>
		</InfoWindow>
	);
};

export default memo(AreaInfoWindow, (prev, post) => {
	const fields: Array<keyof IAreaInfoWindowProps> = ['area', 'isSelected', 'isEditing'];

	return fields.every((field) => prev[field] === post[field]);
});
