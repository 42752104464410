import { IArea } from './areas';
import { IBranch, IVendorBranchDeliveryArea } from './vendors';

// ! enums
export enum EGlobalEtaAction {
	INCREASE = 'increase',
	DECREASE = 'decrease',
}

// ! interfaces
export interface IBulkUpdateBranchResponse {
	successCount: number;
	issues: string[];
}

// * Branch Delivery Areas
export interface IBranchDeliveryAreasData {
	// required
	store_id: IBranch['id'];
	area_id: IArea['id'];
	// optionals
	delivery_fee?: IBranch['delivery']['fee'];
	vehicle_type?: IVendorBranchDeliveryArea['vehicle_type'];
	min_order_delivery_time?: IBranch['delivery']['min_order_delivery_time'];
	max_order_delivery_time?: IBranch['delivery']['max_order_delivery_time'];
}

export interface IBulkUpdateBranchDeliveryAreasPayload {
	store_delivery_areas: IBranchDeliveryAreasData[];
}

// * Branch Preparation Times
export interface IBranchPreparationTimesData {
	// required
	store_id: IBranch['id'];
	// optionals
	preparation_time?: IBranch['info']['preparation_time'];
}

export interface IBulkUpdateBranchPreparationTimesPayload {
	stores: IBranchPreparationTimesData[];
}

// * Global ETA
export interface IBulkUpdateGlobalETAPayload {
	min_amount: number;
	min_factor: EGlobalEtaAction;
	max_amount: number;
	max_factor: EGlobalEtaAction;
}
