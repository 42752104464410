import {
	ELiveTrackingDriverCapacityStatus,
	ELiveTrackingDriverVehicle,
	ILiveTrackingDriver,
	ILiveTrackingFilterConfigItem,
	TLiveTrackingDriversData,
} from 'types/api';
import { Nullable } from 'types/common';

// ! FILTER DRIVERS
type TFilterDriversArgs = {
	driversData: Nullable<TLiveTrackingDriversData>;
	driverCapacityStatusFilters: ELiveTrackingDriverCapacityStatus[];
	driverVehicleFilters: ELiveTrackingDriverVehicle[];
};

export const filterDrivers = ({
	driversData,
	driverVehicleFilters,
	driverCapacityStatusFilters,
}: TFilterDriversArgs): ILiveTrackingDriver[] => {
	const hasFilters = driverCapacityStatusFilters.length || driverVehicleFilters.length;
	if (!driversData || !hasFilters) return [];

	const driversArrays: ILiveTrackingDriver[][] = [];

	driverCapacityStatusFilters.forEach((statusValue) => {
		const vehicleToDriverObj = driversData[statusValue];
		if (!vehicleToDriverObj) return;

		driverVehicleFilters.forEach((vehicleValue) => {
			const drivers = vehicleToDriverObj[vehicleValue];
			if (!drivers?.length) return;

			driversArrays.push(drivers);
		});
	});

	return driversArrays.flat();
};

// ! GET ENABLED FILTERS
export const getEnabledFiltersFromSettings = <T>(config: ILiveTrackingFilterConfigItem<T>[]) => {
	return config.reduce<T[]>((acc, filterSettingsItem) => {
		if (filterSettingsItem.filter) acc.push(filterSettingsItem.value);

		return acc;
	}, []);
};
