import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import {
	ELiveTrackingDeliveryStatus,
	ELiveTrackingDriverCapacityStatus,
	ELiveTrackingDriverVehicle,
	IArea,
	IBranchesLocationData,
	ILiveTrackingDriver,
} from 'types/api';
import { Nullable } from 'types/common';
import { getAreaCenter } from 'components/Areas/helper';
import { filterDrivers, getEnabledFiltersFromSettings } from 'pages/LiveTracking/helpers';
import { IAssignOrderToDriverModalHandle } from 'pages/LiveTracking/liveTrackComponents/AssignOrderToDriverModal/types';
import { useCachedBranchList } from 'pages/LiveTracking/liveTrackComponents/LiveTrackingMap/useCachedBranchList';
import { useDriverLiveTrackSocket } from '../useDriverLiveTrackSocket';
import { BBox } from 'geojson';

export interface ILiveTrackingControllerData extends ReturnType<typeof useLiveTrackingCtrl> {}

export const useLiveTrackingCtrl = () => {
	const navigate = useNavigate();

	const { driversData, socketLoading, driversMetrics, driverFiltersSettings, changeTimeInterval } =
		useDriverLiveTrackSocket();
	const { branchMap, loadMoreBranches } = useCachedBranchList();

	// ! states
	// drivers
	const [selectedDriver, setSelectedDriver] = useState<Nullable<ILiveTrackingDriver>>(null);

	const [showDriversClusters, setShowDriversClusters] = useState(true);
	const [showDriverMarkers, setShowDriverMarkers] = useState(true);
	const [driverCapacityStatusFilters, setDriverCapacityStatusFilters] = useState<ELiveTrackingDriverCapacityStatus[]>(
		[]
	);
	const [driverDeliveryStatusFilters, setDriverDeliveryStatusFilters] = useState<ELiveTrackingDeliveryStatus[]>([]);
	const [driverVehicleFilters, setDriverVehicleFilters] = useState<ELiveTrackingDriverVehicle[]>([]);

	// branches
	const [showBranchesPins, setShowBranchesPins] = useState(false);
	const [showBranchesClusters, setShowBranchesClusters] = useState(true);
	const [selectedBranch, setSelectedBranch] = useState<Nullable<IBranchesLocationData>>(null);

	// map
	const [googleMap, setGoogleMap] = useState<Nullable<google.maps.Map>>(null);
	const [bbox, setBbox] = useState<BBox>([-180, -90, 180, 90]);
	const [zoom, setZoom] = useState(0);

	// area
	const [selectedArea, setSelectedArea] = useState<Nullable<IArea>>(null);

	// ! refs
	const assignOrderRef = useRef<IAssignOrderToDriverModalHandle>(null);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! memos
	// Filtered drivers without frontend changes ('color' field).
	const driversList = useMemo<ILiveTrackingDriver[]>(() => {
		if (!showDriverMarkers) return [];

		return filterDrivers({ driversData, driverVehicleFilters, driverCapacityStatusFilters });
	}, [driversData, driverVehicleFilters, driverCapacityStatusFilters, showDriverMarkers]);

	// ! handlers
	const onToggleShowBranchMarkers = (state: boolean) => {
		setShowBranchesPins(state);
		if (state) {
			loadMoreBranches(googleMap);
		}
	};

	const onToggleShowDriverMarkers = setShowDriverMarkers;
	const onToggleShowDriverClusterMarkers = setShowDriversClusters;
	const onToggleShowBranchClusterMarkers = setShowBranchesClusters;

	// * Branch
	const onBranchCloseInfo = () => {
		setSelectedBranch(null);
	};
	const onBranchClick = setSelectedBranch;

	// * Driver
	const onCloseDriverInfo = () => {
		setSelectedDriver(null);
	};
	const onClickDriverMarker = (driver: ILiveTrackingDriver) => {
		setSelectedDriver(null);
		setSelectedDriver(driver);
	};

	// * MAP
	const onAreaChange = useCallback(
		(area?: IArea) => {
			setSelectedArea(area || null);

			const newCenter = getAreaCenter(area?.polygon?.coordinates, area?.center);

			if (!newCenter || !googleMap) return;

			googleMap.panTo(newCenter);
			googleMap.setZoom(13);
		},
		[googleMap]
	);

	// * SEARCH
	const onDriverSearch = useCallback(
		(driverId: Nullable<number>) => {
			if (!driverId || !googleMap) return;

			const candidate = driversList.find(({ id }) => id === driverId);
			if (!candidate) return;

			googleMap.setCenter(candidate.location);
			googleMap.setZoom(16);
		},
		[driversList, googleMap]
	);

	// * FILTERS
	const onOrderCapacityStatusFiltersChange = useCallback(
		(res: ELiveTrackingDriverCapacityStatus[]) => setDriverCapacityStatusFilters(res),
		[]
	);

	const onOrderDeliveryStatusFiltersChange = useCallback(
		(res: ELiveTrackingDeliveryStatus[]) => setDriverDeliveryStatusFilters(res),
		[]
	);

	const onVehicleTypeFiltersChange = useCallback(
		(res: ELiveTrackingDriverVehicle[]) => setDriverVehicleFilters(res),
		[]
	);

	// * ORDER
	const onMoreOrderDetails = (orderId: number) => {
		navigate(generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId }));
	};

	//
	const loadMoreBranchesWithPrevent = useCallback(() => {
		if (!googleMap || !showBranchesPins) return;

		loadMoreBranches(googleMap);
	}, [googleMap, loadMoreBranches, showBranchesPins]);

	// ! effects
	// drivers
	useEffect(() => {
		// set defaults if filteredDrivers is empty
		if (!driversList.length) {
			// remove selected driver if there are no drivers
			setSelectedDriver(null);
			return;
		}

		// check if selected driver exists in updated filteredDrivers
		setSelectedDriver((prevSelected) => {
			if (!prevSelected) return null;

			const candidate = driversList.find((driverItem) => driverItem.id === prevSelected.id);
			if (!candidate) return null;

			return candidate;
		});
	}, [driversList]);

	// enable filters
	useEffect(() => {
		if (!driverFiltersSettings) return;

		const enabledVehicle = getEnabledFiltersFromSettings(driverFiltersSettings.vehicle);
		setDriverVehicleFilters(enabledVehicle);

		const enabledCapacityStatus = getEnabledFiltersFromSettings(driverFiltersSettings.capacity_status);
		setDriverCapacityStatusFilters(enabledCapacityStatus);

		const enabledDeliveryStatus = getEnabledFiltersFromSettings(driverFiltersSettings.delivery_status);
		setDriverDeliveryStatusFilters(enabledDeliveryStatus);
	}, [driverFiltersSettings]);

	const filteredBranches = useMemo<IBranchesLocationData[]>(() => {
		const branchList = Object.values(branchMap);

		if (selectedArea?.polygon) {
			const selectedAreaPolygon = new google.maps.Polygon({
				paths: selectedArea.polygon.coordinates,
			});

			return branchList.filter((branch) =>
				google.maps.geometry.poly.containsLocation(branch.location, selectedAreaPolygon)
			);
		}

		return branchList;
	}, [branchMap, selectedArea]);

	// ! render
	return {
		socketLoading,

		// Filters
		driverVehicleFilters,
		driverCapacityStatusFilters,
		driverDeliveryStatusFilters,

		driversMetrics,
		driverFiltersSettings,

		//
		showBranchesPins,
		onToggleShowBranchMarkers,
		showBranchesClusters,
		onToggleShowBranchClusterMarkers,
		showDriverMarkers,
		onToggleShowDriverMarkers,
		showDriversClusters,
		onToggleShowDriverClusterMarkers,

		// Areas
		selectedArea,

		// Drivers InfoWindow
		selectedDriver,
		onClickDriverMarker,
		onCloseDriverInfo,

		// Branch InfoWindow
		selectedBranch,
		onBranchClick,
		onBranchCloseInfo,

		// Utils
		onAreaChange,
		onDriverSearch,
		onOrderCapacityStatusFiltersChange,
		onOrderDeliveryStatusFiltersChange,
		onVehicleTypeFiltersChange,
		onMoreOrderDetails,
		changeTimeInterval,

		// Map
		googleMap,
		setGoogleMap,
		bbox,
		setBbox,
		zoom,
		setZoom,

		// Driver Markers
		driversList,

		// Branches Markers
		filteredBranches,
		loadMoreBranches: loadMoreBranchesWithPrevent,

		// Assign Order Modal
		assignOrderRef,
	};
};

const LiveTrackingContext = createContext<ILiveTrackingControllerData>({} as ILiveTrackingControllerData);

const useLiveTracking = () => {
	return useContext(LiveTrackingContext);
};

const LiveTrackingProvider = ({ children }: PropsWithChildren) => {
	const contextData = useLiveTrackingCtrl();

	// ! render
	return <LiveTrackingContext.Provider value={contextData}>{children}</LiveTrackingContext.Provider>;
};

export { LiveTrackingProvider, useLiveTracking };
