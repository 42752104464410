import { THttpRequestConfig } from 'hooks';
import {
	IBulkUpdateBranchDeliveryAreasPayload,
	IBulkUpdateBranchPreparationTimesPayload,
	IBulkUpdateGlobalETAPayload,
} from 'types/api';
import {
	IVendorRankingAddVendorBranches,
	TVendorRankingUpdatePatch,
} from 'pages/LogisticsBulkActions/VendorRankings/types';

export const LOGISTICS_BULK_ACTIONS_API = {
	get: (): THttpRequestConfig => ({
		url: 'operations/logistics/ranking',
		method: 'GET',
	}),
	reset: (): THttpRequestConfig => ({
		url: 'operations/logistics/ranking',
		method: 'DELETE',
	}),
	add: (data: IVendorRankingAddVendorBranches): THttpRequestConfig => ({
		url: 'operations/logistics/ranking/add',
		method: 'POST',
		data,
	}),
	bulkUpdate: (data: TVendorRankingUpdatePatch): THttpRequestConfig => ({
		url: 'operations/logistics/bulk-update-ranking',
		method: 'PATCH',
		data,
	}),

	bulkUpdateBranchDeliveryAreas: (data: IBulkUpdateBranchDeliveryAreasPayload): THttpRequestConfig => ({
		url: 'operations/logistics/store-delivery-areas/bulk',
		method: 'PATCH',
		data,
	}),

	bulkUpdateBranchPreparationTimes: (data: IBulkUpdateBranchPreparationTimesPayload): THttpRequestConfig => ({
		url: 'operations/logistics/stores/bulk',
		method: 'PATCH',
		data,
	}),

	bulkUpdateGlobalETA: (data: IBulkUpdateGlobalETAPayload): THttpRequestConfig => ({
		url: 'operations/logistics/bulk-update-eta',
		method: 'POST',
		data,
	}),
};
