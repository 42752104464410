import { FC, memo } from 'react';
import { IMultipleOrdersDriverIconProps } from './types';
import { BASE_ICON_BY_VEHICLE, FIRST_LINE_Y, STRIPE_HEIGHT } from './config';

const MultipleOrdersDriverIcon: FC<IMultipleOrdersDriverIconProps> = ({
	stripesColors,
	driverColor,
	vehicleType,
	rotateAngle,
}) => {
	const svgHeight = 48 + Math.max(0, stripesColors.length - 3) * STRIPE_HEIGHT;
	const BaseIconComponent = BASE_ICON_BY_VEHICLE[vehicleType];

	// ! render
	return (
		<BaseIconComponent
			height={svgHeight}
			driverColor={driverColor}
			rotateAngle={rotateAngle}
		>
			{stripesColors.map((color, index) => {
				const rectY = FIRST_LINE_Y + index * STRIPE_HEIGHT;

				return (
					<rect
						key={index}
						x='9'
						y={rectY}
						width='30'
						height={STRIPE_HEIGHT}
						rx='2'
						fill={color}
					/>
				);
			})}
		</BaseIconComponent>
	);
};

export default memo(MultipleOrdersDriverIcon);
