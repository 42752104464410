import { EStatus } from 'types/common';

// ! Enums
export enum ERequiredDocumentTypes {
	DRIVER = 'driver',
}

// ! Interfaces
export interface IRequiredDocument {
	id: number;
	key: string;
	name: string;
	name_ar: string;
	type: ERequiredDocumentTypes;
	status: EStatus;
}

export interface ICreateNewReqDocumentData {
	name: IRequiredDocument['name'];
	name_ar: IRequiredDocument['name_ar'];
	type: IRequiredDocument['type'];
	status?: IRequiredDocument['status'];
}

export interface IEditReqDocumentData extends ICreateNewReqDocumentData {
	id: IRequiredDocument['id'];
}
