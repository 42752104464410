import { FC, memo } from 'react';
import { ICircleWithMinusProps } from './types';

const MinusCircle: FC<ICircleWithMinusProps> = ({ color }) => {
	return (
		<svg
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.61035 0C3.74342 0 0.610352 3.13306 0.610352 7C0.610352 10.8669 3.74342 14 7.61035 14C11.4773 14 14.6104 10.8669 14.6104 7C14.6104 3.13306 11.4773 0 7.61035 0ZM3.88455 8.12903C3.69825 8.12903 3.54584 7.97661 3.54584 7.79032V6.20968C3.54584 6.02339 3.69825 5.87097 3.88455 5.87097H11.3362C11.5224 5.87097 11.6749 6.02339 11.6749 6.20968V7.79032C11.6749 7.97661 11.5224 8.12903 11.3362 8.12903H3.88455Z'
				fill={color}
			/>
		</svg>
	);
};

export default memo(MinusCircle);
