import { TIME_FORMAT } from 'utils/date';
import { IBranchTiming, IBranchTimingsSchedule } from 'types/api';
import { TShortWeekDay } from 'types/common';
import { generateTimeSlotsInMinutes } from './WorkshiftTableEditor/helper';

export const TIMINGS_DATE_PICKER_FORMAT = TIME_FORMAT;

export const DEFAULT_OPEN_TIME = '00:01';

export const DEFAULT_CLOSE_TIME = '23:59';
export const DEFAULT_CLOSE_TIME_NUMBER = 1440;

export const DEFAULT_SHIFT_DURATION = 30;

export const EMPTY_TIMINGS_DATA: Record<TShortWeekDay, IBranchTiming> = {
	ALL: { day: 'ALL', schedule: [] },
	MON: { day: 'MON', schedule: [] },
	TUE: { day: 'TUE', schedule: [] },
	WED: { day: 'WED', schedule: [] },
	THU: { day: 'THU', schedule: [] },
	FRI: { day: 'FRI', schedule: [] },
	SAT: { day: 'SAT', schedule: [] },
	SUN: { day: 'SUN', schedule: [] },
};

export const WEEK_DAYS: TShortWeekDay[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const emptyScheduleItem: IBranchTimingsSchedule = {
	close_time: DEFAULT_CLOSE_TIME,
	open_time: DEFAULT_OPEN_TIME,
};

export const TIME_SLOTS_IN_MINUTES: number[] = generateTimeSlotsInMinutes();

export const MAX_SHIFTS_PER_DAY = 3;
