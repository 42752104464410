import { ILiveTrackingSettings, ILiveTrackingSettingsSuccess, TLiveTrackingStatusToColor } from 'types/api';
import { VEHICLE_FILTER_ICONS, getStatusFiltersIcons } from './config';

interface ISerializeArgs {
	settings: ILiveTrackingSettingsSuccess;
	statusToColor: TLiveTrackingStatusToColor;
}

export const serializeFiltersSettings = ({ settings, statusToColor }: ISerializeArgs): ILiveTrackingSettings => {
	const statusFiltersIcons = getStatusFiltersIcons(statusToColor);

	const vehicle = settings.vehicle.map((vehicleItem) => ({
		...vehicleItem,
		icon: VEHICLE_FILTER_ICONS[vehicleItem.value],
	}));

	const delivery_status = settings.delivery_status.map((statusItem) => ({
		...statusItem,
		icon: statusFiltersIcons[statusItem.value],
	}));

	const capacity_status = settings.capacity_status.map((statusItem) => ({
		...statusItem,
		icon: statusFiltersIcons[statusItem.value],
	}));

	return {
		...settings,
		vehicle,
		delivery_status,
		capacity_status,
	};
};
