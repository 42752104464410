import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { SEARCH_ENGINE_ROUTES } from './SearchEngine/routes.search-engine';
import { NumberOutlined } from '@ant-design/icons';

export const ANALYTICS_ROUTES: TRouteObject = {
	path: 'analytics',
	handle: {
		translationKey: 'analytics',
		permissions: [APP_PERMISSIONS.analytics.view],
		icon: <NumberOutlined />,
		showOnNav: true,
		isNavigateable: false,
	},
	children: [SEARCH_ENGINE_ROUTES],
};
