import { FC } from 'react';
import { IColorSet } from './types';
import { Button, ButtonProps, ConfigProvider, theme } from 'antd';

interface ICustomButtonProps extends ButtonProps {
	colorTokens: IColorSet;
}

export const CustomButton: FC<ICustomButtonProps> = ({ colorTokens, ...props }) => {
	const { token } = theme.useToken();

	return (
		<ConfigProvider
			theme={{
				token: { ...token, ...colorTokens },
				components: {
					Button: {
						colorPrimary: colorTokens.colorPrimary,
					},
				},
			}}
		>
			<Button
				{...props}
				color='primary'
			/>
		</ConfigProvider>
	);
};
