import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { LocalTimeDate, useAuth, useCustomer, useNewHttpClient } from 'hooks';
import { CUSTOMER_API } from 'configs/api';
import { CARI_UNLIMITED_COUNTRIES_WHITE_LIST, DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ECustomerStatus, ICustomer } from 'types/api';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import Spinner from 'components/Spinner';
import ErrorPage from 'pages/ErrorPage';
import { CustomerCariUnlimitedDetailsCard } from './CustomerCariUnlimitedDetails';
import { CustomerEditModal, ICustomerEditModalHandle } from './CustomerEditModal';
import { App, Button, Card, Col, Descriptions, Row, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const DetailsTab: FC = () => {
	const {
		customerId,
		data: customerData,
		fetchHttpClient: { error, isLoading },
		fetchCustomerInformation,
	} = useCustomer();
	const { message } = App.useApp();
	const { hasPermission } = useAuth();

	const { t: tCustomers } = useTranslation('customers');
	const { t: tCommon } = useTranslation('common');

	// ! refs
	const customerEditModalRef = useRef<ICustomerEditModalHandle>(null);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! http client
	const updateHttpClient = useNewHttpClient<ICustomer>();

	// ! useEffects
	useEffect(() => {
		fetchCustomerInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! handlers
	const onOpenCustomerEditModal = (data: ICustomer) => {
		customerEditModalRef.current?.open(data);
	};

	const onConfirmEditCustomer = (payload: ICustomer) => {
		const id = payload.id;

		return updateHttpClient.request({
			requestConfig: CUSTOMER_API.update(id, payload),
			successCallback: () => {
				message.success(tCustomers('customer_details.tabs.details.update_message', { id }), 5);

				fetchCustomerInformation();
			},
		});
	};

	// ! render
	if (error) {
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!customerId || !customerData || isLoading) {
		return <Spinner defaultAntdSpinner />;
	}

	const { first_name, last_name, status, email, country_code, phone_number, uuid, language, created_at } =
		customerData;

	const isCustomerEditBtnDisabled =
		status === ECustomerStatus.DELETED || !hasPermission(APP_PERMISSIONS.customer.update);

	const canShowCariUnlimitedDetails =
		hasPermission([APP_PERMISSIONS.cari_unlimited.view, APP_PERMISSIONS.cari_unlimited.subscription.view]) &&
		CARI_UNLIMITED_COUNTRIES_WHITE_LIST.includes(countryIsoTwoCode as ESupportedCountriesIsoTwoCodes);

	return (
		<>
			<Row
				key='row-1'
				gutter={[16, 16]}
			>
				<Col flex='1'>
					<Card key='main-info'>
						<Space
							direction='vertical'
							size='middle'
						>
							<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
								<Descriptions.Item label={tCustomers('shared.entity.name')}>
									{first_name} {last_name}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.email')}>{email}</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.phone_number')}>
									({country_code}) {phone_number}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.language')}>
									{language}
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.uuid')}>
									<CopyToClipBoard>{uuid}</CopyToClipBoard>
								</Descriptions.Item>
								<Descriptions.Item label={tCustomers('shared.entity.created_at')}>
									<LocalTimeDate date={created_at} />
								</Descriptions.Item>
							</Descriptions>

							<Button
								key='edit'
								icon={<EditOutlined />}
								disabled={isCustomerEditBtnDisabled} // If is read only customer or no permissions
								onClick={() => onOpenCustomerEditModal(customerData)}
							>
								{tCommon('action_buttons.edit')}
							</Button>
						</Space>
					</Card>
				</Col>

				{canShowCariUnlimitedDetails && (
					<Col flex='1'>
						<CustomerCariUnlimitedDetailsCard details={customerData.cari_unlimited_subscription} />
					</Col>
				)}
			</Row>

			<CustomerEditModal
				ref={customerEditModalRef}
				confirmLoading={updateHttpClient.isLoading}
				onConfirm={onConfirmEditCustomer}
			/>
		</>
	);
};

export default DetailsTab;
