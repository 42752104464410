import { FC, PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from 'configs/common';
import { INavMenuRoute } from 'configs/routes';
import { ESupportedLanguages } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { Flex } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { GlobalOutlined } from '@ant-design/icons';

export const getLanguageMenu = (
	currentLanguage: ESupportedLanguages,
	handleLanguageChange: (newLang: ESupportedLanguages) => void
): ItemType => {
	const newLanguageConfig =
		currentLanguage === ESupportedLanguages.ENGLISH
			? SUPPORTED_LANGUAGES[ESupportedLanguages.ARABIC]
			: SUPPORTED_LANGUAGES[ESupportedLanguages.ENGLISH];

	return {
		key: 'change_language_cta',
		icon: <GlobalOutlined />,
		label: (
			<Flex
				gap='middle'
				align='center'
			>
				<TranslatedLabel i18nKey={`languages.${newLanguageConfig.iso_two_code}`} />
				<img
					src={newLanguageConfig.flag}
					alt='flag'
					loading='lazy'
					className='flag_img'
				/>
			</Flex>
		),
		onClick: () => {
			handleLanguageChange(newLanguageConfig.iso_two_code);
		},
	};
};

const NavLinkLabelWrapper: FC<{ route: INavMenuRoute } & PropsWithChildren> = ({
	route: { path, defaultListParams },
	children,
}): JSX.Element => {
	const finalPath = defaultListParams ? `${path}?${new URLSearchParams(defaultListParams)}` : path!;

	return <NavLink to={finalPath}>{children}</NavLink>;
};

export const convertIRouteToMenuItemType = (route: INavMenuRoute, index: number): ItemType => {
	const { path, translationKey, children, icon, isNavigateable } = route;

	const ContentLabel = () => <TranslatedLabel i18nKey={`nav_items.${translationKey}`} />;

	const menuKey = (translationKey ?? path ?? `nav_item_${index}`).replaceAll('-', '_');

	const item: ItemType = {
		key: menuKey,
		icon,
		label: (
			<span
				className='ant-menu-title-content'
				style={{ textTransform: 'capitalize' }}
			>
				{path && (isNavigateable ?? true) ? (
					<NavLinkLabelWrapper route={route}>
						<ContentLabel />
					</NavLinkLabelWrapper>
				) : (
					<ContentLabel />
				)}
			</span>
		),
		children: children?.map(convertIRouteToMenuItemType),
	};

	return item;
};
