import { DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { ECustomerStatus } from 'types/api';
import TranslatedLabel from 'components/TranslatedLabel';
import { DescriptionsProps } from 'antd';

export const DESCRIPTIONS_PROPS: DescriptionsProps = {
	...DEFAULT_DESCRIPTIONS_PROPS,
	styles: {
		label: {
			...DEFAULT_DESCRIPTIONS_PROPS.labelStyle,
			alignSelf: 'start',
		},
	},
};

export const STATUS_SELECT_OPTIONS = [
	{
		value: ECustomerStatus.ACTIVE,
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_options.${ECustomerStatus.ACTIVE}`}
			/>
		),
	},
	{
		value: ECustomerStatus.BLOCKED,
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_options.${ECustomerStatus.BLOCKED}`}
			/>
		),
	},
];
