import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountry, getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useAuth, useCustomer, useGetFormValues, useNewHttpClient } from 'hooks';
import { transformICustomerOrderToTableData } from './helper';
import { preventOrderCodeSearchRequest } from 'utils/preventOrderCodeSearchRequest';
import { CUSTOMER_API, LIST_DEFAULT_PARAMS, REFUNDS_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { CUSTOMER_ORDER_LIST_TABLE_COLUMNS } from './configs';
import { ICreateRefundData, ICreateRefundForm, ICustomerOrder } from 'types/api';
import { Nullable } from 'types/common';
import { ICustomerOrderListTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import TableWrapper from 'components/Table';
import OrderRefundModal from './OrderRefundModal';
import { App, Form } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DollarOutlined, EyeOutlined } from '@ant-design/icons';

const OrdersTab: FC = () => {
	const { customerId, isReadOnlyData } = useCustomer();
	const { hasPermission } = useAuth();
	const { message } = App.useApp();
	const { getFormValues } = useGetFormValues();
	const { t: tCommon } = useTranslation('common');
	const { t: tCustomers } = useTranslation('customers');

	const [refundForm] = Form.useForm<ICreateRefundForm>();
	const [refundingOrder, setRefundingOrder] = useState<Nullable<ICustomerOrderListTableData>>(null);

	// ! http client
	const refundHttpClient = useNewHttpClient();

	// ! selectors
	const selectedCountry = useSelector(getSelectedCountry);
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! memos
	const orderListRequestConfig = useMemo(() => CUSTOMER_API.getCustomersOrderList(customerId), [customerId]);

	// ! handlers
	const onRefundClick = (data: ICustomerOrderListTableData) => setRefundingOrder(data);

	const onRefundModalCancel = () => {
		refundForm.resetFields();
		setRefundingOrder(null);
	};

	const onRefundModalConfirm = async () => {
		const formValues = await getFormValues(refundForm);
		if (!formValues || !refundingOrder?.id || !selectedCountry?.id) return;

		const data: ICreateRefundData = {
			...formValues,
			order_id: refundingOrder.id,
		};

		refundHttpClient.request({
			requestConfig: REFUNDS_API.create(data),
			successCallback: () => {
				setRefundingOrder(null);
				refundForm.resetFields();
				message.success(tCustomers('customer_details.tabs.orders.refund_success'), 3);
			},
		});
	};

	// ! table columns
	const tableColumns = useMemo<ColumnsType<ICustomerOrderListTableData>>(
		() => {
			const canCreate = hasPermission(APP_PERMISSIONS.customer.order.issue_order_refund);

			return [
				...CUSTOMER_ORDER_LIST_TABLE_COLUMNS(countryIsoTwoCode),
				{
					key: 'actions',
					title: tCustomers('shared.entity.actions'),
					width: getActionButtonsColumnWidth(2),
					fixed: 'right',
					render: (_, record) => {
						const disableRefund = isReadOnlyData || !canCreate || !record.actions.can_refund;

						const actions: IActionMenuItem[] = [
							{
								type: 'button',
								title: tCustomers('shared.action_buttons.refund'),
								icon: <DollarOutlined />,
								disabled: disableRefund,
								actionCb: () => onRefundClick(record),
							},
							{
								type: 'link',
								title: tCommon('action_buttons.view_details'),
								icon: <EyeOutlined />,
								navigateTo: generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, {
									countryIsoTwoCode,
									orderId: record.id,
								}),
							},
						];
						return <ActionsMenu actions={actions} />;
					},
				},
			];
		},
		[hasPermission] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// ! render
	return (
		<>
			{/* TABLE */}
			<TableWrapper<ICustomerOrderListTableData, ICustomerOrder>
				disallowVerticalScroll
				columns={tableColumns}
				requestConfig={orderListRequestConfig}
				defaultControlSizes='middle'
				searchParam='code'
				searchPlaceholder={tCustomers('customer_details.tabs.orders.search_placeholder')}
				preventSearchRequest={preventOrderCodeSearchRequest}
				defaultParams={{ ...LIST_DEFAULT_PARAMS, customer_id: customerId }}
				transformDataToTableData={transformICustomerOrderToTableData}
			/>

			{/* REFUND MODAL */}
			<OrderRefundModal
				form={refundForm}
				isOpen={!!refundingOrder}
				confirmLoading={refundHttpClient.isLoading}
				title={tCustomers('customer_details.tabs.orders.refund_modal.title', { id: refundingOrder?.id })}
				onOk={onRefundModalConfirm}
				onCancel={onRefundModalCancel}
			/>
		</>
	);
};

export default OrdersTab;
