import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { IBranchTiming } from 'types/api';
import { Nullable } from 'types/common';
import { IWorkShiftDayTimeModalHandle, IWorkShiftDayTimeModalProps } from './types';
import TimingsDay from '../../TimingsDay';
import { ToStringDate } from '../../TimingsRange/helpers';
import { emptyScheduleItem } from '../../config';
import { TTimeType } from '../../types';
import { Dayjs } from 'dayjs';
import { Modal } from 'antd';

export const WorkShiftDayTimeModal = forwardRef<IWorkShiftDayTimeModalHandle, IWorkShiftDayTimeModalProps>(
	({ workshiftController }, ref) => {
		const { t: tWorkshifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });
		const { t: tCommon } = useTranslation('common');

		// ! states
		const [isOpen, setIsOpen] = useState<boolean>(false);

		const [timesOfSelectedDay, setTimesOfSelectedDay] = useState<IBranchTiming>({ day: 'ALL', schedule: [] });

		// ! handlers
		const onOpen = (times: IBranchTiming) => {
			setTimesOfSelectedDay(times);
			setIsOpen(true);
		};
		const onClose = () => {
			setIsOpen(false);
		};

		const onConfirm = () => {
			workshiftController.applyScheduleWithConfirmation(timesOfSelectedDay);

			onClose();
		};

		const onCancel = () => {
			onClose();
		};

		// * state manipulation
		const removeScheduledItemWithConfirmation = (index: number) => {
			setTimesOfSelectedDay((prev) => {
				const filteredScheduled = prev.schedule.filter((_, scheduleIndex) => {
					return scheduleIndex !== index;
				});

				return {
					...prev,
					schedule: filteredScheduled,
				};
			});
		};

		const addScheduleItemWithConfirmation = () => {
			setTimesOfSelectedDay((prev) => {
				prev.schedule.push(emptyScheduleItem);
				return {
					...prev,
				};
			});
		};

		const timeChangeWithConfirmation = (index: number, timeType: TTimeType, timeValue: Nullable<Dayjs>) => {
			setTimesOfSelectedDay((prev) => {
				const schedule = prev.schedule.map((scheduleItem, scheduleIndex) => {
					if (scheduleIndex !== index) return scheduleItem;

					return {
						...scheduleItem,
						[timeType]: ToStringDate(timeValue, timeType),
					};
				});

				return {
					...prev,
					schedule,
				};
			});
		};

		// ! ref hook
		useImperativeHandle(
			ref,
			() => {
				const api: IWorkShiftDayTimeModalHandle = {
					isOpen,
					open: onOpen,
					close: onClose,
				};

				return api;
			},
			[isOpen]
		);

		// ! return
		return (
			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={isOpen}
				title={tWorkshifts('edit_modal.title', { day: tCommon(`weekdays.${timesOfSelectedDay.day}`) })}
				okText={tCommon('action_buttons.confirm')}
				onOk={onConfirm}
				onCancel={onCancel}
			>
				{timesOfSelectedDay && (
					<TimingsDay
						day={timesOfSelectedDay.day}
						schedule={timesOfSelectedDay.schedule}
						isEdit
						onTimeChange={timeChangeWithConfirmation}
						onAddScheduleItem={addScheduleItemWithConfirmation}
						onRemoveScheduleItem={removeScheduledItemWithConfirmation}
					/>
				)}
			</Modal>
		);
	}
);
