import { FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { IArea } from 'types/api';
import { Nullable } from 'types/common';
import { ISelectedAreaListProps, TableDataType } from './types';
import { getCenter } from 'components/Areas/helper';
import { Button, Checkbox, Flex, Typography as T, Table, TableProps, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { AimOutlined, EyeFilled, EyeInvisibleOutlined } from '@ant-design/icons';
import { useMap } from '@vis.gl/react-google-maps';

const SelectedAreaList: FC<ISelectedAreaListProps> = ({
	areas,
	hiddenAreas,
	isViewAllAreas,
	selectedAreas,
	handleViewAllArea,
	setHoveredAreaId,
	replaceSelectedAreaList,
	toggleHiddenArea,
	selectArea,
}) => {
	const map = useMap();

	const { t: tCommon } = useTranslation('common');
	const { t: tAreas } = useTranslation('areas', { keyPrefix: 'areas_map.drawer' });

	// ! states
	const [searchArea, setSearchArea] = useState<string>();

	// ! memos
	const tableData = useMemo(
		() =>
			areas
				.filter(
					(area) =>
						!searchArea ||
						area.name.toLocaleLowerCase().includes(searchArea) ||
						area.name_ar.toLocaleLowerCase().includes(searchArea)
				)
				.map((area): TableDataType => ({ ...area, selected: !selectedAreas[area.id] }))

				.sort((a, b) => {
					const ORDER_BY_SELECT_WEIGHT = 1000000000000;

					const orderBySelected = Number(a.selected) - Number(b.selected);

					const orderByName = a.name.localeCompare(b.name);

					return orderByName + orderBySelected * ORDER_BY_SELECT_WEIGHT;
				}),

		[areas, searchArea, selectedAreas]
	);

	// ! handlers
	const onAimButtonClick = (ev: MouseEvent<HTMLElement>, map: Nullable<google.maps.Map>, area: IArea) => {
		ev.preventDefault();
		ev.stopPropagation();

		if (!map) return;

		toggleHiddenArea(area.id, false);

		var bounds = new google.maps.LatLngBounds();
		area.polygon?.coordinates.forEach((coordinate) => bounds.extend(coordinate));

		// * double fitBounds for Forcing map relocation to show area
		map.fitBounds(bounds);
		map.fitBounds(bounds);

		setHoveredAreaId(area.id);
		handleViewAllArea(true);
	};

	const onToggleHideStatusButtonClick = (ev: MouseEvent<HTMLElement>, area: IArea) => {
		ev.preventDefault();
		ev.stopPropagation();

		toggleHiddenArea(area.id);
	};

	// ! render
	const rowSelection: TableProps<TableDataType>['rowSelection'] = {
		type: 'checkbox',
		columnWidth: 26,
		selectedRowKeys: Object.entries(selectedAreas)
			.filter(([_, value]) => value)
			.map(([key, _]) => +key),
		onSelect: ({ id }) => selectArea(id),
		onChange: (selectedRowKeys: React.Key[], _, info) => {
			if (info.type === 'all') {
				replaceSelectedAreaList(selectedRowKeys.map((id) => id as IArea['id']));
			}
		},
	};

	const tableColumns: ColumnsType<TableDataType> = [
		{
			title: tCommon('table.headers.name'),
			width: 180,
			render: (_, area) => {
				return <Tooltip title={`#${area.id} - ${area.name} - ${area.name_ar}`}>{area.name}</Tooltip>;
			},
		},
		{
			title: tCommon('table.headers.actions'),
			width: 72,
			render: (_, area) => {
				const isAreaHidden = hiddenAreas[area.id];

				const center = area.polygon?.coordinates ? getCenter(area.polygon?.coordinates) : undefined;

				return (
					<Flex gap='small'>
						<Button
							size='small'
							disabled={!center}
							icon={<AimOutlined />}
							onClick={(ev) => onAimButtonClick(ev, map, area)}
						/>

						<Tooltip title={isAreaHidden ? tCommon('action_buttons.show') : tCommon('action_buttons.hide')}>
							<Button
								size='small'
								disabled={!isViewAllAreas}
								type={isAreaHidden ? 'default' : 'dashed'}
								icon={isAreaHidden ? <EyeFilled /> : <EyeInvisibleOutlined />}
								onClick={(ev) => onToggleHideStatusButtonClick(ev, area)}
							/>
						</Tooltip>
					</Flex>
				);
			},
		},
	];

	return (
		<Flex
			vertical
			gap={12}
			className='h-100'
		>
			{/* HEADER */}
			<Flex justify='space-between'>
				<T.Title level={5}>{tAreas('selected_areas')}</T.Title>
				<Button>
					<Flex
						gap={8}
						onClick={() => handleViewAllArea()}
					>
						{tAreas('actions.view_all')}
						<Checkbox checked={isViewAllAreas} />
					</Flex>
				</Button>
			</Flex>

			<Search
				allowClear
				placeholder={tCommon('table.wrapper.search_placeholder')}
				onSearch={(text) => setSearchArea(text.toLocaleLowerCase())}
			/>

			{/* TABLE */}
			<div className='flex-fill'>
				<AutoSizer>
					{({ height: parentHeight, width }: AutoSizer['state']) => {
						const maxHeight = 800;
						const finalContainerHeight = Math.min(parentHeight, maxHeight);
						const tableHeader = 40;

						return (
							<div style={{ maxHeight: finalContainerHeight, width }}>
								<Table<TableDataType>
									virtual
									rowKey='id'
									size='small'
									pagination={false}
									scroll={{ y: finalContainerHeight - tableHeader }}
									dataSource={tableData}
									columns={tableColumns}
									rowSelection={rowSelection}
									onRow={({ id }) => ({
										onClick: () => selectArea(id),
									})}
								/>
							</div>
						);
					}}
				</AutoSizer>
			</div>
		</Flex>
	);
};

export default SelectedAreaList;
