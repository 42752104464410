import { FC } from 'react';
import { ELiveTrackingDriverVehicle } from 'types/api';
import { IBaseIconProps } from './types';
import CarBaseIcon from './CarBaseIcon';
import MotorcycleBaseIcon from './MotorcycleBaseIcon';

export const FIRST_LINE_Y = 35;

export const STRIPE_HEIGHT = 4;

export const BASE_ICON_BY_VEHICLE: Record<ELiveTrackingDriverVehicle, FC<IBaseIconProps>> = {
	[ELiveTrackingDriverVehicle.MOTORCYCLE]: MotorcycleBaseIcon,
	[ELiveTrackingDriverVehicle.CAR]: CarBaseIcon,
};
