import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ECampaignFormFields, EPromotionType, IPromotionCreatePayloadTemp, IPromotionOfferLabel } from 'types/api';

export const PROMOTIONS_API = {
	list: (
		type: EPromotionType,
		params?: Partial<Record<ECampaignFormFields, undefined | string>>
	): THttpRequestConfig => ({
		url: '/operations/promotions',
		method: 'GET',
		params: { type, ...params },
	}),

	create: (payload: IPromotionCreatePayloadTemp): THttpRequestConfig => ({
		url: '/operations/promotions',
		method: 'POST',
		data: payload,
	}),

	get: (id: number): THttpRequestConfig => ({
		url: `/operations/promotions/${id}`,
		method: 'GET',
	}),
	patch: (id: number, payload: IPromotionCreatePayloadTemp): THttpRequestConfig => ({
		url: `/operations/promotions/${id}`,
		method: 'PATCH',
		data: payload,
	}),
	generateCode: (): THttpRequestConfig => ({ url: `/operations/promotions/generate-code`, method: 'POST' }),
	// operations
	launch: (id: number): THttpRequestConfig => ({
		url: `/operations/promotions/${id}/launch`,
		method: 'POST',
	}),

	pause: (id: number): THttpRequestConfig => ({
		url: `/operations/promotions/${id}/pause`,
		method: 'POST',
	}),
	resume: (id: number): THttpRequestConfig => ({ url: `/operations/promotions/${id}/resume`, method: 'POST' }),
	stop: (id: number): THttpRequestConfig => ({ url: `/operations/promotions/${id}/stop`, method: 'POST' }),
	reactivate: (id: number): THttpRequestConfig => ({
		url: `/operations/promotions/${id}/reactivate`,
		method: 'POST',
	}),
	revoke: (id: number): THttpRequestConfig => ({ url: `/operations/promotions/${id}/revoke`, method: 'POST' }),

	getAffectedVendorsAndBranches: (id: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `/operations/promotions/${id}/vendors`,
		method: 'GET',
		params,
	}),

	assignOfferLabels: (
		id: IPromotionOfferLabel['id'],
		offer_label_ids: IPromotionOfferLabel['id'][]
	): THttpRequestConfig => ({
		url: `/operations/promotions/${id}/assign-offer-labels`,
		method: 'POST',
		data: { offer_label_ids },
	}),
};
