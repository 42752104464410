import { Nullable } from 'types/common';
import { DEFAULT_BRANCH_MARKER_SIZE, DEFAULT_DRIVER_MARKER_SIZE } from './config';

// ! DRIVER MARKER SIZE
export const calculateDriverMarkerSize = (newZoom: number | undefined): number => {
	if (!newZoom) return DEFAULT_DRIVER_MARKER_SIZE;

	if (newZoom > 18) {
		return DEFAULT_DRIVER_MARKER_SIZE;
	}

	if (newZoom > 13 && newZoom < 19) {
		return 36;
	}

	if (newZoom <= 13) {
		return 30;
	}

	return DEFAULT_DRIVER_MARKER_SIZE;
};

// ! BRANCH MARKER SIZE
export const calculateBranchMarkerSize = (newZoom: number | undefined): number => {
	if (!newZoom || newZoom > 18) {
		return DEFAULT_BRANCH_MARKER_SIZE;
	}

	if (newZoom > 13 && newZoom < 19) return 34;

	return 28;
};

// ! BAP BOUNDS
export const getMapBounds = (map: Nullable<google.maps.Map>) => {
	const mapBounds = map?.getBounds();
	const ne = mapBounds?.getNorthEast();
	const sw = mapBounds?.getSouthWest();

	return { ne, sw };
};
