import { FC, memo } from 'react';
import { ICheckCircleProps } from './types';

const CheckCircle: FC<ICheckCircleProps> = ({ color }) => {
	return (
		<svg
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.6105 7.00006C14.6105 10.8661 11.4765 14.0001 7.61041 14.0001C3.74437 14.0001 0.610352 10.8661 0.610352 7.00006C0.610352 3.13402 3.74437 0 7.61041 0C11.4765 0 14.6105 3.13402 14.6105 7.00006ZM6.80072 10.7065L11.9943 5.51294C12.1707 5.33659 12.1707 5.05063 11.9943 4.87427L11.3556 4.2356C11.1793 4.05922 10.8933 4.05922 10.7169 4.2356L6.48137 8.47115L4.50388 6.49366C4.32753 6.3173 4.04157 6.3173 3.86518 6.49366L3.22651 7.13233C3.05016 7.30868 3.05016 7.59464 3.22651 7.771L6.16202 10.7065C6.33841 10.8829 6.62434 10.8829 6.80072 10.7065Z'
				fill={color}
			/>
		</svg>
	);
};

export default memo(CheckCircle);
