import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reformatDate } from './helpers';
import { ITimingsRangeProps } from './types';
import { TIMINGS_DATE_PICKER_FORMAT } from '../config';
import { Dayjs } from 'dayjs';
import { Button, Flex, TimePicker, Tooltip } from 'antd';
import { RangePickerTimeProps } from 'antd/es/time-picker';
import { DeleteOutlined } from '@ant-design/icons';

const TimingsRange: FC<ITimingsRangeProps> = ({ scheduleItem, onRemove, onTimeChange, isEdit = true }) => {
	const { open_time, close_time } = scheduleItem;

	const { t: tCommon } = useTranslation('common');
	const { t: tVendorTimings } = useTranslation('vendors', { keyPrefix: 'vendor_details.timings' });

	const openTime = reformatDate(open_time, 'open_time');
	const closeTime = reformatDate(close_time, 'close_time');

	// ! state
	const [isOpenTimeEditOpen, setOpenTimeEditOpen] = useState(false);

	// ! handlers
	const onChangeTimeRange: RangePickerTimeProps<Dayjs>['onChange'] = (dates) => {
		if (dates?.[0]) {
			onTimeChange('open_time', dates[0]);
		}
		if (dates?.[1]) {
			onTimeChange('close_time', dates[1]);
		}
	};

	// ! render
	return (
		<Flex
			gap={12}
			align='center'
		>
			{/* PICKER */}
			<TimePicker.RangePicker
				value={[openTime, closeTime]}
				format={TIMINGS_DATE_PICKER_FORMAT}
				placeholder={[tVendorTimings('open_time.placeholder'), tVendorTimings('close_time.placeholder')]}
				allowClear={false}
				inputReadOnly={!isEdit}
				open={isOpenTimeEditOpen}
				onOpenChange={(newState) => setOpenTimeEditOpen(isEdit && newState)}
				onChange={onChangeTimeRange}
			/>

			{/* BUTTON */}
			{isEdit && (
				<Tooltip title={tCommon('action_buttons.remove')}>
					<Button
						size='small'
						shape='default'
						icon={<DeleteOutlined />}
						onClick={onRemove}
					/>
				</Tooltip>
			)}
		</Flex>
	);
};

export default TimingsRange;
