import { EStatus } from 'types/common';
import { IArea } from './areas';
import { IVendor } from './vendors';

export enum EVerticalType {
	DEFAULT = 'default', // Could be parent or child but only the child can have vendors and the parent will have sub-verticals.
	INDEPENDENT = 'independent', // parent non-premium verticals that cannot have children. They can have vendors assigned directly.
	PREMIUM = 'premium', // They must be parent without any sub-vertical. Vendors are assigned to them directly.
}

export enum EVerticalLevel {
	VERTICAL = 'parent',
	SUB_VERTICAL = 'child',
}

// ! interfaces
export interface IVerticalAreas {
	area_ids: IArea['id'][];
}

export interface IVerticalVendor {
	id: IVendor['id'];
	name: IVendor['info']['name'];
}

export interface IPremiumVendor {
	id: IVendor['id'];
	name: string;
}

export interface IVertical {
	id: number;
	name: string;
	name_ar: string;
	internal_name: string;
	type: EVerticalType;
	image: string;
	status: EStatus;
	sort_order: number;
	associated_sub_verticals?: number[];
	level: EVerticalLevel;

	total_sub_verticals: string;
	total_vendors: string;

	// for the Premium vertical
	premium_vendor?: IPremiumVendor;
}

export interface IGenericVertical {
	id: IVertical['id'];
	name: IVertical['name'];
	name_ar: IVertical['name_ar'];
}

// * CRUD
export interface IVerticalCreatePayload {
	vendor_id: IVendor['id'];
	name: IVertical['name'];
	name_ar: IVertical['name_ar'];
	internal_name: IVertical['internal_name'];
	type: IVertical['type'];
	level: IVertical['level'];
	parent_id?: number;
	sort_order: IVertical['sort_order'];
	status?: IVertical['status'];
}

export interface IVerticalUpdatePayload {
	name?: IVertical['name'];
	name_ar?: IVertical['name_ar'];
	internal_name?: IVertical['internal_name'];
	sort_order?: IVertical['sort_order'];
	status?: IVertical['status'];
	area_ids?: IVerticalAreas['area_ids'];
	level?: IVertical['level'];
}

export interface IVerticalAddVendorsPayload {
	vendor_ids: IVendor['id'][];
}
