import { JSX } from 'react';
import { CAR_VEHICLE_TYPE_ICON, MOTORCYCLE_VEHICLE_TYPE_ICON } from 'configs/common';
import {
	ELiveTrackingDeliveryStatus,
	ELiveTrackingDriverCapacityStatus,
	ELiveTrackingDriverVehicle,
	TLiveTrackingStatusToColor,
} from 'types/api';
import { CheckCircle, CloseCircle, ExclamationCircle, MinusCircle } from 'components/Icons';

export const VEHICLE_FILTER_ICONS: Record<ELiveTrackingDriverVehicle, JSX.Element> = {
	[ELiveTrackingDriverVehicle.MOTORCYCLE]: MOTORCYCLE_VEHICLE_TYPE_ICON,
	[ELiveTrackingDriverVehicle.CAR]: CAR_VEHICLE_TYPE_ICON,
};

export const getStatusFiltersIcons = (
	statusToColor: TLiveTrackingStatusToColor
): Record<ELiveTrackingDeliveryStatus | ELiveTrackingDriverCapacityStatus, JSX.Element> => {
	return {
		[ELiveTrackingDeliveryStatus.ASSIGNED]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.ASSIGNED]} />
		),
		[ELiveTrackingDeliveryStatus.HEADING_TO_STORE]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.HEADING_TO_STORE]} />
		),
		[ELiveTrackingDeliveryStatus.ARRIVED_AT_STORE]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.ARRIVED_AT_STORE]} />
		),
		[ELiveTrackingDeliveryStatus.PICKED_UP_ORDER]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.PICKED_UP_ORDER]} />
		),
		[ELiveTrackingDeliveryStatus.HEADING_TO_CUSTOMER]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.HEADING_TO_CUSTOMER]} />
		),
		[ELiveTrackingDeliveryStatus.ARRIVED_AT_CUSTOMER]: (
			<MinusCircle color={statusToColor[ELiveTrackingDeliveryStatus.ARRIVED_AT_CUSTOMER]} />
		),
		[ELiveTrackingDeliveryStatus.DISPUTED]: (
			<ExclamationCircle color={statusToColor[ELiveTrackingDeliveryStatus.DISPUTED]} />
		),
		[ELiveTrackingDriverCapacityStatus.FREE]: (
			<CheckCircle color={statusToColor[ELiveTrackingDriverCapacityStatus.FREE]} />
		),
		[ELiveTrackingDriverCapacityStatus.PARTIAL_CAPACITY]: (
			<MinusCircle color={statusToColor[ELiveTrackingDriverCapacityStatus.PARTIAL_CAPACITY]} />
		),
		[ELiveTrackingDriverCapacityStatus.FULL_CAPACITY]: (
			<CloseCircle color={statusToColor[ELiveTrackingDriverCapacityStatus.FULL_CAPACITY]} />
		),
	};
};
