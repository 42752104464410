import { createContext, useContext, useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { CONFIGURATIONS_API } from 'configs/api';
import { IGoogleMapsConfig } from 'types/api';
import { useNewHttpClient } from './useHttpClient';
import { APIProvider } from '@vis.gl/react-google-maps';

export interface IGoogleMapsApiData extends ReturnType<typeof useGoogleMapsApiKeyController> {}

const useGoogleMapsApiKeyController = () => {
	const fetchHttpClient = useNewHttpClient<IGoogleMapsConfig>();

	// ! handlers
	const handleFetchGoogleMapsApiKey = () => {
		fetchHttpClient.request({
			requestConfig: CONFIGURATIONS_API.getMapConfig(),
		});
	};

	// ! useEffect
	useEffect(() => {
		handleFetchGoogleMapsApiKey();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		mapApiKey: fetchHttpClient.response?.apiKey,
		mapApiKeyLoading: fetchHttpClient.isLoading,
	};
};

const GoogleMapsApiContext = createContext<IGoogleMapsApiData>({} as IGoogleMapsApiData);

const useGoogleMapsApi = () => {
	return useContext(GoogleMapsApiContext);
};

const GoogleMapsProvider = ({ children }: PropsWithChildren) => {
	const googleMapsController = useGoogleMapsApiKeyController();

	if (!googleMapsController.mapApiKey) return null;

	return (
		<GoogleMapsApiContext.Provider value={googleMapsController}>
			<APIProvider
				apiKey={googleMapsController.mapApiKey}
				libraries={['places', 'geometry', 'marker']}
			>
				{children}
			</APIProvider>
		</GoogleMapsApiContext.Provider>
	);
};

export { useGoogleMapsApi, GoogleMapsProvider };
