import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDeliveryTimes } from 'hooks';
import { isNumber } from 'utils/validationUtils/isNumber';
import { EDeliveryTimesOptions, IDeliveryTimeSectionProps, TTime } from './types';
import { DELIVERY_TIME_OPTIONS_LIST } from 'pages/Vendors/VendorDetails/Tabs/Branches/AddEditBranch/BranchForm/configs';
import styles from './DeliveryTImeSection.module.css';
import { Flex, Radio, RadioChangeEvent, Select, Typography as T } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DeliveryTimeSection: FC<IDeliveryTimeSectionProps> = ({
	disabled,
	areaId,
	selectedTime,
	setSelectedDeliveryTimesMap,
}) => {
	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area',
	});

	// ! states
	const [selectedRadioOption, setSelectedRadioOption] = useState<EDeliveryTimesOptions>(
		EDeliveryTimesOptions.FROM_PARENT
	);
	const [selectedTimeValue, setSelectedTimeValue] = useState<IDeliveryTimes>({ min: null, max: null });

	// ! memos
	const isTimeSelectorDisabled = useMemo(() => {
		return disabled || selectedRadioOption === EDeliveryTimesOptions.FROM_PARENT;
	}, [disabled, selectedRadioOption]);

	const showAlertMsg = useMemo(() => {
		if (isNumber(selectedTimeValue.min) && isNumber(selectedTimeValue.max)) {
			return selectedTimeValue.min > selectedTimeValue.max;
		}
		return false;
	}, [selectedTimeValue]);

	// ! handlers
	const updateAreaDeliveryTime = (option: EDeliveryTimesOptions, value: IDeliveryTimes) => {
		setSelectedDeliveryTimesMap((prev) => {
			const newState = { ...prev };

			const newValue = option === EDeliveryTimesOptions.FROM_PARENT ? { min: null, max: null } : value;
			newState[areaId] = newValue;

			return newState;
		});

		if (option === EDeliveryTimesOptions.FROM_PARENT) {
			setSelectedTimeValue({ min: null, max: null });
		}
	};

	const onTimeSelectorChange = (value: number, type: TTime) => {
		const newValue = {
			...selectedTimeValue,
			[type]: value,
		};

		setSelectedTimeValue(newValue);

		// update area delivery times
		updateAreaDeliveryTime(EDeliveryTimesOptions.SPECIFIC_TIME, newValue);
	};

	const onChangeSelectedOption = ({ target }: RadioChangeEvent) => {
		const selectedOption: EDeliveryTimesOptions = target.value;

		// update selected option value
		setSelectedRadioOption(selectedOption);

		// update area delivery times
		updateAreaDeliveryTime(selectedOption, selectedTimeValue);
	};

	// ! effects
	// * set the correct time value/option when the area id or selected time changes
	useEffect(() => {
		// time values
		setSelectedTimeValue(selectedTime);

		// option
		const hasTimes = Object.values(selectedTime).some((time) => time !== null);
		setSelectedRadioOption(hasTimes ? EDeliveryTimesOptions.SPECIFIC_TIME : EDeliveryTimesOptions.FROM_PARENT);
	}, [areaId, selectedTime]);

	// ! render
	return (
		<Flex
			gap={6}
			vertical
		>
			{/* LABEL */}
			<T.Text>{tVendorBranchDeliveryArea(`delivery_time.label`)}:</T.Text>

			{/* OPTIONS */}
			<Radio.Group
				size='small'
				disabled={disabled}
				value={selectedRadioOption}
				onChange={onChangeSelectedOption}
			>
				<Flex
					gap={6}
					vertical
				>
					<Radio value={EDeliveryTimesOptions.FROM_PARENT}>
						{tVendorBranchDeliveryArea('delivery_time.options.from_parent')}
					</Radio>

					<Flex
						gap='small'
						className={styles.time_select_container}
					>
						<Radio value={EDeliveryTimesOptions.SPECIFIC_TIME}>
							<T.Text>{tVendorBranchDeliveryArea('delivery_time.options.specific_time')}</T.Text>
						</Radio>
						<Select<number>
							size='small'
							className={styles.time_select}
							value={selectedTimeValue.min}
							options={DELIVERY_TIME_OPTIONS_LIST}
							disabled={isTimeSelectorDisabled}
							onChange={(value) => onTimeSelectorChange(value, 'min')}
						/>
						<T.Text>-</T.Text>
						<Select<number>
							size='small'
							className={styles.time_select}
							value={selectedTimeValue.max}
							options={DELIVERY_TIME_OPTIONS_LIST}
							disabled={isTimeSelectorDisabled}
							onChange={(value) => onTimeSelectorChange(value, 'max')}
						/>
					</Flex>
				</Flex>
			</Radio.Group>

			{/* ALERT MSG */}
			{showAlertMsg && (
				<T.Text className={styles.alert_msg}>
					<ExclamationCircleOutlined />
					&nbsp;
					{tVendorBranchDeliveryArea('delivery_time.alert')}
				</T.Text>
			)}
		</Flex>
	);
};

export default memo(DeliveryTimeSection);
