import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/common';
import { App, FormInstance } from 'antd';

export const useGetFormValues = () => {
	const { message } = App.useApp();
	const { t: tCommon } = useTranslation();

	const getFormValues = <ReturnType>(form: FormInstance<ReturnType>): Promise<Nullable<ReturnType>> =>
		form.validateFields().catch((error) => {
			console.warn('Form Validate Failed:', error);
			return null;
		});

	const getFormValuesWithReject = <ReturnType>(form: FormInstance<ReturnType>): Promise<ReturnType> =>
		form.validateFields().catch((error) => {
			console.warn('Form Validate Failed:', error);
			return Promise.reject(error);
		});

	const defaultCatchHandler = () => {
		message.error(tCommon('form_validations.form_has_errors'));
	};

	return { getFormValues, getFormValuesWithReject, defaultCatchHandler };
};
