import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { FieldTimeOutlined, FileSyncOutlined, RadiusSettingOutlined } from '@ant-design/icons';
import RankingsIcon from 'assets/svgs/ranking.svg';

// ! Lazy module imports
const VendorRankings = lazy(() => import('pages/LogisticsBulkActions/VendorRankings'));
const BulkUpdateBranchDeliveryAreas = lazy(() => import('pages/LogisticsBulkActions/BulkUpdateBranchDeliveryAreas'));
const BulkUpdateBranchPreparationTimes = lazy(
	() => import('pages/LogisticsBulkActions/BulkUpdateBranchPreparationTimes')
);
const BulkUpdateGlobalETA = lazy(() => import('pages/LogisticsBulkActions/BulkUpdateGlobalETA'));

export const LOGISTICS_BULK_ACTIONS_ROUTES: TRouteObject = {
	path: 'logistics-bulk-actions',
	handle: {
		translationKey: 'logistics_bulk_actions',
		permissions: [APP_PERMISSIONS.logistics.view],
		icon: <FileSyncOutlined />,
		showOnNav: true,
		isNavigateable: false,
	},
	children: [
		{
			path: 'branch-delivery-areas',
			element: <BulkUpdateBranchDeliveryAreas />,
			handle: {
				translationKey: 'branch_delivery_areas',
				icon: <RadiusSettingOutlined />,
				showOnNav: true,
				permissions: [APP_PERMISSIONS.logistics.bulk_update.store_delivery_areas],
			},
		},
		{
			path: 'branch-preparation-times',
			element: <BulkUpdateBranchPreparationTimes />,
			handle: {
				translationKey: 'branch_preparation_times',
				icon: <FieldTimeOutlined />,
				showOnNav: true,
				permissions: [APP_PERMISSIONS.logistics.bulk_update.stores],
			},
		},
		{
			path: 'bulk-update-global-eta',
			element: <BulkUpdateGlobalETA />,
			handle: {
				translationKey: 'bulk_update_global_eta',
				icon: <FieldTimeOutlined />,
				showOnNav: true,
				permissions: [APP_PERMISSIONS.logistics.bulk_update.stores_eta],
			},
		},
		{
			path: 'rankings',
			element: <VendorRankings />,
			handle: {
				translationKey: 'vendors_rankings',
				permissions: [APP_PERMISSIONS.logistics.get_stores_ranking],
				icon: (
					<img
						alt='logo'
						loading='lazy'
						src={RankingsIcon}
						width={14}
						height={14}
					/>
				),
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: true,
			},
		},
	],
};
