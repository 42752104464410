import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSelectedCountryCurrency } from 'store/selectors';
import { useCustomer, useNewHttpClient } from 'hooks';
import { transformCustomerTransactionsToTableData } from './helpers';
import { CUSTOMER_API, LIST_DEFAULT_PARAMS } from 'configs/api';
import { ICustomerWalletBalance, ICustomerWalletTransaction } from 'types/api';
import { Nullable } from 'types/common';
import { ICustomerWalletTransactionTableData } from './types';
import { BUTTON_COLOR_SETS, CustomButton, EButtonColorTypes } from 'components/CustomButton';
import TableWrapper from 'components/Table';
import styles from './WalletHistoryTab.module.css';
import { WALLET_TRANSACTIONS_TABLE_COLUMNS } from './config';
import { Button, Space, Tooltip, Typography } from 'antd';

const WalletHistoryTab: FC = () => {
	const { customerId } = useCustomer();

	const { t: tCustomerWallet } = useTranslation('customers', { keyPrefix: 'customer_details.tabs.wallet_history' });

	// ! http clients
	const fetchBalanceHttpClient = useNewHttpClient<ICustomerWalletBalance>();

	// ! state
	const [walletBalance, setWalletBalance] = useState<Nullable<ICustomerWalletBalance>>(null);

	const totalBalanceStr =
		walletBalance && Object.keys(walletBalance).length > 0
			? `${walletBalance.balance} ${walletBalance.currency}`
			: 'N/A';

	// ! selectors
	const countryCurrency = useSelector(getSelectedCountryCurrency);

	// ! memos
	const fetchTransactionsConfig = useMemo(
		() => CUSTOMER_API.getCustomersWalletTransactions(customerId, countryCurrency),
		[customerId, countryCurrency]
	);

	// ! handlers
	const getWalletBalance = () => {
		fetchBalanceHttpClient.request({
			requestConfig: CUSTOMER_API.getCustomersWalletBalance(customerId, countryCurrency),
			successCallback: (response) => {
				setWalletBalance(response);
			},
		});
	};

	// ! effects
	useEffect(
		() => {
			getWalletBalance();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[customerId]
	);

	// ! render
	if (!customerId || !countryCurrency) {
		return null;
	}

	return (
		<TableWrapper<ICustomerWalletTransactionTableData, ICustomerWalletTransaction>
			disallowVerticalScroll
			searchParam='comment'
			columns={WALLET_TRANSACTIONS_TABLE_COLUMNS}
			requestConfig={fetchTransactionsConfig}
			defaultControlSizes='middle'
			searchPlaceholder={tCustomerWallet('search_placeholder')}
			defaultParams={{ ...LIST_DEFAULT_PARAMS, customer_id: customerId }}
			transformDataToTableData={transformCustomerTransactionsToTableData}
			tableExtraActions={
				<Space size='large'>
					<Typography className={styles.total_balance}>
						<Space
							size='small'
							style={{ alignItems: 'flex-start' }}
						>
							{tCustomerWallet('current_balance')}
							<span className={styles.total_balance_value}>{totalBalanceStr}</span>
						</Space>
					</Typography>
					<Tooltip title='Please contact the Support team to proceed with these actions.'>
						<Space wrap>
							<Button
								type='primary'
								disabled
							>
								{tCustomerWallet('debit_btn')}
							</Button>
							<CustomButton
								type='primary'
								disabled
								colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_green]}
							>
								{tCustomerWallet('credit_btn')}
							</CustomButton>
						</Space>
					</Tooltip>
				</Space>
			}
		/>
	);
};

export default WalletHistoryTab;
