import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESearchEngineTabsKeys } from '../types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import TranslatedLabel from 'components/TranslatedLabel';
import { SearchOutlined } from '@ant-design/icons';
import RankingIcon from 'assets/svgs/ranking.svg';

const NotHitVendorSearchesList = lazy(() => import('pages/Analytics/SearchEngine/Tabs/NotHitVendorSearches'));
const TopHitVendorSearchesList = lazy(() => import('pages/Analytics/SearchEngine/Tabs/TopHitVendorSearches'));

const SEARCH_ENGINE_TAB_ROUTES_CONFIG: Record<ESearchEngineTabsKeys, TRouteObject> = {
	[ESearchEngineTabsKeys.NOT_HIT_VENDOR_SEARCHES]: {
		path: ESearchEngineTabsKeys.NOT_HIT_VENDOR_SEARCHES,
		element: (
			<ListParamsRouteWrapper key='not-hit-searches'>
				<NotHitVendorSearchesList />
			</ListParamsRouteWrapper>
		),
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='analytics'
					i18nKey={`search_engine.tabs.${ESearchEngineTabsKeys.NOT_HIT_VENDOR_SEARCHES.replaceAll(
						'-',
						'_'
					)}.title`}
				/>
			),
			icon: <SearchOutlined />,
			permissions: [APP_PERMISSIONS.analytics.search_engine.view],
		},
	},
	[ESearchEngineTabsKeys.TOP_HIT_SEARCHES]: {
		path: ESearchEngineTabsKeys.TOP_HIT_SEARCHES,
		element: (
			<ListParamsRouteWrapper key='top-hit-searches'>
				<TopHitVendorSearchesList />
			</ListParamsRouteWrapper>
		),
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='analytics'
					i18nKey={`search_engine.tabs.${ESearchEngineTabsKeys.TOP_HIT_SEARCHES.replaceAll('-', '_')}.title`}
				/>
			),
			icon: (
				<img
					alt='top-hit-icon'
					loading='lazy'
					src={RankingIcon}
					width={14}
					height={14}
				/>
			),
			permissions: [APP_PERMISSIONS.analytics.search_engine.view],
		},
	},
};

export const SEARCH_ENGINE_ROUTES_TAB_ROUTES: TRouteObject[] = Object.values(SEARCH_ENGINE_TAB_ROUTES_CONFIG);
