import { FC, memo } from 'react';
import { ICloseCircleProps } from './types';

const CloseCircle: FC<ICloseCircleProps> = ({ color }) => {
	return (
		<svg
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.61035 0C3.74352 0 0.610352 3.13317 0.610352 7C0.610352 10.8668 3.74352 14 7.61035 14C11.4772 14 14.6104 10.8668 14.6104 7C14.6104 3.13317 11.4772 0 7.61035 0ZM9.38391 9.53413L7.61035 7.76058L5.83679 9.53413C5.62814 9.74279 5.28487 9.74279 5.07622 9.53413C4.97189 9.42981 4.91804 9.29183 4.91804 9.15384C4.91804 9.01587 4.97189 8.87788 5.07622 8.77356L6.84977 7L5.07622 5.22644C4.97189 5.12212 4.91804 4.98413 4.91804 4.84615C4.91804 4.70817 4.97189 4.57019 5.07622 4.46587C5.28487 4.25721 5.62814 4.25721 5.83679 4.46587L7.61035 6.23942L9.38391 4.46587C9.59256 4.25721 9.93583 4.25721 10.1445 4.46587C10.3531 4.67452 10.3531 5.01779 10.1445 5.22644L8.37093 7L10.1445 8.77356C10.3531 8.98221 10.3531 9.32548 10.1445 9.53413C9.93583 9.74615 9.59256 9.74615 9.38391 9.53413Z'
				fill={color}
			/>
		</svg>
	);
};

export default memo(CloseCircle);
