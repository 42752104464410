import { TEmptyFunction } from 'types/common';
import { IVendorTabProps } from '../../types';

export enum EBranchTabsKeys {
	DETAILS = 'details',
	MENUS = 'menus',
	TIMINGS = 'timings',
	DELIVERY_AREA = 'delivery-area',
	CITC = 'citc',
}

export interface IBranchTabProps extends IVendorTabProps {
	branchId: number;
	onEdit?: TEmptyFunction;
}
